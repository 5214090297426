// index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import store from './store'
import App from 'App';
import './index.css';

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from 'context';

const container = document.getElementById('app');
const root = createRoot(container);


root.render(
  <BrowserRouter>
    <Provider store={store}>
      <MaterialUIControllerProvider>
        <App />
      </MaterialUIControllerProvider>
    </Provider>
  </BrowserRouter>

);
