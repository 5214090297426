import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import MDInput from "components/MDInput";
import MDBox from 'components/MDBox';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Delete, Edit } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Autocomplete, TextField } from '@mui/material';


const EditSkill = ({ data, user, token, handleDataFetch }) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const [skills, setSkills] = useState([]);
    const [myskills, setMySkills] = useState([]);
    const [selectedSkills, setSelectedSkills] = useState([]);

    const [error, setError] = useState(false);
    const [dateError, setDateError] = useState('');
    const [showFullDescription, setShowFullDescription] = useState(false);



    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const id = data?.user?.id;
            // Make the API call to update certification details
            const response = await axios.post(`${apiUrl}api/professional/add-skill/${id}`, { skills: selectedSkills }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            toast.success("Skill details saved successfully !!", {
                className: "toast-message",
            });
            // Close modal
            handleDataFetch();

        } catch (error) {
            console.error('Error updating certification details:', error);
        }
    }

    const fetchSkillList = async () => {
        try {
            const response = await fetch(`${apiUrl}api/professional/skills`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            const result = await response.json();
            setSkills(result?.skills || []);
            setSelectedSkills(result?.user_skills || []); 
            console.log(result)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fetchSkillList();

    }, [data]);

    return (

        <div className="card skill-wrapper mb-4 mt-3">
            {/* Card Header */}
            <div className="card-header d-flex justify-content-between align-items-center">
                <h5>Skills</h5>
            </div>
            {/* Card Body */}

            <div className="card-body skill-form">
                <form onSubmit={handleSubmit}>
                    <div className="form-group col-md-12">
                        <label className="text-muted">Boost your profile by listing your skills (Please use enter key to add skills)</label>
                        <div className="d-flex mt-2 align-items-center">
                            <Autocomplete
                                multiple
                                options={skills}
                                freeSolo
                                className="flex-grow-1 bg-white"
                                value={selectedSkills}
                                onChange={(event, newValue) => {
                                    setSelectedSkills(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} label="" />}
                            />
                        </div>
                    </div>
                    <div className='float-end mt-3'>
                        <button type="submit" className="btn-more">Save changes</button>
                    </div>
                </form>
            </div>

        </div>
    );
}

export default EditSkill;
