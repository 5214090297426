import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

function ProtectedRoute() {
  const [cookies] = useCookies(['token']);
  const token = cookies['token'];
  const location = useLocation(); // to get the current path

  return token ? (
    <Outlet />
  ) : (
    // Add `from` parameter to the redirect
    <Navigate to={`/sign-in?from=${encodeURIComponent(location.pathname)}`} />
  );
}

export default ProtectedRoute;