import React, { useEffect, useState } from 'react';

import BasicLayout from "layouts/authentication/components/BasicLayout";
import EmployerRegistration from "./employer/index.js";
import JobSeekerRegistration from "./seeker/index.js";
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
function Cover() {
  const [registrationType, setRegistrationType] = useState('employer');
  const { slug } = useParams();

  const handleRegistrationType = (type) => {
    setRegistrationType(type);
  };

  return (
    <BasicLayout>
      <div className='signup-wrapper'>
        <div className='container'>
          <div className='row vh-100 d-flex align-items-center justify-content-center'>
            <div className='col-md-9 signup-box mb-3'>
              <div className='row d-flex align-items-center justify-content-center'>
                <div className='col-md-6 left-content p-5'>
                <h4>Signup</h4>
                <hr></hr>
                <p>Get register with one of the best Constructions project employee management Portal.</p>
                </div>
                <div className='col-md-6 mb-3'>
                  {registrationType === 'employer' ?
                  <EmployerRegistration handleRegistrationType={handleRegistrationType} />
                  :
                  <JobSeekerRegistration handleRegistrationType={handleRegistrationType} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      {/* <Grid container  justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={9} md={6} lg={4} xl={6}>
          <div className="container d-flex flex-column w-50 mx-auto">
            
          </div>
        </Grid>
      </Grid> */}
    </BasicLayout>


  );
}

export default Cover;
