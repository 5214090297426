import React, { useState, useEffect } from 'react';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
const EditAboutModal = ({ show, handleClose, data, token, handleDataFetch }) => {
    const [about, setAbout] = useState('');
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';

    const handleChange = (e) => {
        const { value } = e.target;
        if (value.length <= 500) {
            setAbout(value);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const id = data?.user?.id;
            if (id) {
                const response = await axios.put(`${apiUrl}api/professional/update-about/${id}`, { about }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                console.log(response.data); // Handle success response
                toast.success("About details updated successfully !!", {
                    className: "toast-message",
                });
                handleClose();
                handleDataFetch();
            }
        } catch (error) {
            console.error('Error updating about', error); // Log error
            // toast.error("Failed to save user info. Please try again later.", {
            //     className: "toast-message",
            // });
        }

    };
    useEffect(() => {
        setAbout(data?.details?.about)
    }, [data]);

    return (
        <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit About</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true" className='p-2'>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body w-100 h-100">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <p className='text-sm-start'><small>You can write about your years of experience, industry, or skills. People also talk about their achievements or previous job experiences.</small></p>
                                <textarea className="form-control" style={{ height: '150px' }} name='about' value={about} onChange={handleChange}></textarea>
                                <div className="text-sm-end text-muted"><small>{about?.length}/500 characters</small>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleClose}>Close</button>
                                <button type="submit" className="btn btn-primary">Save changes</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default EditAboutModal