import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../store/slices/authSlice';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import countryData from '../../../assets/data/country-state.json';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format

const Edit = () => {
    const [error, setError] = useState(null);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const { token, user } = useSelector(selectAuth);
    const [showAlert, setShowAlert] = useState(false);
    const navigate = useNavigate()
    const { slug } = useParams();
    const [sectors, setSectors] = useState([]);
    const [projectJob, setProjectJob] = useState();
    const [selectedSector, setSelectedSector] = useState(null);
    const [selectedSubsector, setSelectedSubsector] = useState(null);
    const [subsectors, setSubsectors] = useState([]);
    const [showTender, setShowTender] = useState(false);
    const [stateCountryList, setStateCountryList] = useState([]);
    const [locationError, setLocationError] = useState(false);
    const [selectedCurrentLocation, setSelectedCurrentLocation] = useState('');
    const [id, setId] = useState('');
    const [deleteId, setDeleteId] = useState([]);
    const [proceedClicked, setProceedClicked] = useState(false);
    const [errors, setErrors] = useState({});

    // State variables to store form data
    const [formData, setFormData] = useState({
        name: '',
        category: '',
        sector: '',
        sub_sector: '',
        stage: '',
        tender_reference_number: '',
        deadline: '',
        location: selectedCurrentLocation,
        jd_image: null,
        features: '',
        budget: '',
        key_personnel_details: [{
            title: '',
            category: '',
            sector: '',
            sub_sector: '',
            stage: '',
            experience: '',
            deadline: '',
            location: '',
            language: '',
            salary: '',
            job_type: '',
            personnel_type: '',
            description: ''
        }]
        // key_personnel_details: [{ key_personnel_type: 'National', name: '', description: '' }]
    });
    const [keyPersonnelDetails, setKeyPersonnelDetails] = useState([
        { key_personnel_type: 'National', name: '', description: ' ' }
    ]);

    // Function to handle input changes
    const handleInputChange = (e) => {
        const { name, type } = e.target;
        if (type === 'file') {
            setFormData({ ...formData, [name]: e.target.files[0] });
        } else {
            const { value } = e.target;
            setFormData({ ...formData, [name]: value });
        }
    };

    // Function to handle input changes in personnel field
    // const handlePersonnelChange = (index, value) => {
    //   const updatedPersonnel = [...formData.key_personnel_details];
    //   updatedPersonnel[index].name = value;
    // setFormData({
    //   ...formData,
    //   key_personnel_details: updatedPersonnel
    // });
    // };

    // Function to add a new empty personnel field
    // Function to handle changes in personnel fields
    const validatePersonnel = (personnel) => {
        const personnelErrors = {};
        if (!personnel.title) personnelErrors.title = 'Job Title is required';
        if (!personnel.location) personnelErrors.location = 'Location is required';
        if (!personnel.language) personnelErrors.language = 'Languages are required';
        if (!personnel.stage) personnelErrors.stage = 'Job Stage is required';
        if (!personnel.sector) personnelErrors.sector = 'Sector is required';
        if (!personnel.sub_sector) personnelErrors.sub_sector = 'Sub Sector is required';
        if (!personnel.category) personnelErrors.category = 'Category is required';
        if (!personnel.job_type) personnelErrors.job_type = 'Job Type is required';
        if (!personnel.experience) personnelErrors.experience = 'Experience is required';
        if (!personnel.deadline) personnelErrors.deadline = 'Deadline is required';
        if (!personnel.personnel_type) personnelErrors.personnel_type = 'Personnel Type is required';
        if (!personnel.description) personnelErrors.description = 'Description is required';

        return personnelErrors;
    };

    const handlePersonnelChange = (index, field, value) => {
        const updatedPersonnel = [...formData.key_personnel_details];
        updatedPersonnel[index][field] = value;
        setFormData({
            ...formData,
            key_personnel_details: updatedPersonnel
        });
    };

    // Function to add a new empty personnel field
    const addPersonnelField = () => {
        const lastPersonnel = formData.key_personnel_details[formData.key_personnel_details.length - 1];
        const personnelErrors = validatePersonnel(lastPersonnel);

        if (Object.keys(personnelErrors).length === 0) {
            setFormData({
                ...formData,
                key_personnel_details: [...formData.key_personnel_details, {
                    title: '',
                    category: '',
                    sector: '',
                    sub_sector: '',
                    stage: '',
                    experience: '',
                    deadline: '',
                    location: '',
                    language: '',
                    salary: '',
                    job_type: '',
                    personnel_type: '',
                    description: ''
                }]
            });
            setErrors({});
        } else {
            // setErrors({ key_personnel_details: personnelErrors });
            toast.error("Please fill all required fields", {
                className: "toast-message",
            });
        }
    };

    // Function to remove a personnel field
    const removePersonnelField = (index) => {
        if (window.confirm('Are you sure you want to delete this? Once deleted, you cannot retrieve it.')) {

            const updatedPersonnel = [...formData.key_personnel_details];
            updatedPersonnel.splice(index, 1);
            setFormData({
                ...formData,
                key_personnel_details: updatedPersonnel
            });
        }
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Project Name is required';
        if (!formData.category) newErrors.category = 'Category is required';
        if (!formData.location) newErrors.location = 'Location is required';
        if (!formData.sector) newErrors.sector = 'Sector is required';
        if (!formData.sub_sector) newErrors.sub_sector = 'Sub Sector is required';
        if (!formData.stage) newErrors.stage = 'Project Stage is required';
        if (showTender && !formData.tender_reference_number) newErrors.tender_reference_number = 'Tender Reference Number is required';
        if (!formData.budget) newErrors.budget = 'Project Cost is required';
        if (!formData.deadline) newErrors.deadline = 'Deadline is required';
        if (!formData.features) newErrors.features = 'Features is required';
        const selectedDate = new Date(formData.deadline);
        const currentDate = new Date(today);
        // if (selectedDate < currentDate) newErrors.deadline = 'Deadline cannot be in the past.';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const addProject = () => {
        if (validate()) {
            setProceedClicked(true)
        }


    };


    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const updatedFormData = { ...formData, location: selectedCurrentLocation };

            const response = await axios.post(`${apiUrl}api/edit-project/${id}`, updatedFormData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            // Redirect or show success message here
            navigate('/projects');
        } catch (error) {
            console.error('Error creating project:', error);
            // Handle error
            setError('Error creating project. Please try again.');
        }
    };

    const handleApprove = async (projectId, message) => {
        // Show a confirmation dialog
        setShowAlert(false)
        const isConfirmed = window.confirm(message === 'publish' ? 'Are you sure you want to publish this project?' : 'Are you sure you want to draft this project?');

        if (isConfirmed) {
            try {
                const response = await fetch(`${apiUrl}api/project/${projectId}/update-status`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const result = await response.json();

                if (response.ok) {
                    fetchData();
                    setShowAlert(true);
                    console.log("result", result)
                    toast.success(result?.message);


                } else {
                    // Handle error, e.g., show an error message
                    console.error('Failed to approve project');
                }
            } catch (error) {
                // Handle network error
                console.error('Network error:', error);
            }
        }
    };

    const handleDelete = async (projectId) => {
        // Show a confirmation dialog
        setShowAlert(false)
        const isConfirmed = window.confirm('Are you sure you want to delete this project? Once deleted, all associated jobs will be removed');
        if (isConfirmed) {
            try {
                const response = await fetch(`${apiUrl}api/projects/${projectId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const result = await response.json();

                if (response.ok) {
                    navigate('/my-projects');
                } else {
                    // Handle error, e.g., show an error message
                    console.error('Failed to approve project');
                }
            } catch (error) {
                // Handle network error
                console.error('Network error:', error);
            }
        }
    };
    const fetchData = async () => {
        if (slug && token) {
            try {
                
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }

                const url = `${apiUrl}api/project/${slug}/edit-project`;
                axios.get(url, { headers })
                    .then((response) => {
                        if (response) {
                            console.log(response?.data?.project)
                            setProjectJob(response?.data?.project);
                            const project = response?.data?.project;

                            if (project) {
                                // Store fetched data in a local variable to ensure data consistency
                                const keyDetails = project?.jobs?.map(element => ({
                                    personnel_type: element?.personnel_type || 'National',
                                    title: element?.title || '',
                                    category: element?.category || '',
                                    sector: element?.sector || '',
                                    sub_sector: element?.sub_sector || '',
                                    stage: element?.stage || '',
                                    experience: element?.experience || '',
                                    deadline: element?.deadline || '',
                                    location: element?.location || '',
                                    language: element?.language || '',
                                    salary: element?.salary || '',
                                    job_type: element?.job_type || '',

                                    description: element?.description || '',
                                })) || [];
                                // Update the formData directly with keyDetails
                                const updatedFormData = {
                                    ...formData, // Current form data
                                    ...project, // Update with new project data
                                    location: project.location ?? selectedCurrentLocation, // Handle default location
                                    key_personnel_details: keyDetails
                                };

                                setFormData(updatedFormData);
                                setId(project?.id);
                                setSelectedCurrentLocation(project?.location)
                                setKeyPersonnelDetails(keyDetails);

                                // Trigger sector fetch only if the sector is defined
                                if (project?.sector) {
                                    fetchSectorsData(project.sector, project.sub_sector);
                                }



                            }

                        }
                    })
                    .catch((error) => {
                        if (error) {
                            console.log(error)
                        }
                    })


                // }
            } catch (error) {
                console.error('Error creating project:', error);
                // Handle error
                setError('Error creating project. Please try again.');
            }
        }
    };

    const fetchSectorsData = async (sectorName, subSectorName) => {
        try {
            const response = await fetch(`${apiUrl}api/get-sectors`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();

            setSectors(result);

            // Find the sector and update the state
            const sector = result.find(s => s.name === sectorName);

            if (sector) {
                setSelectedSector(sector.id);
                setFormData(prevFormData => ({
                    ...prevFormData,
                    sector: sector.id,
                    sub_sector: subSectorName,
                }));
                setSubsectors(sector.children);
                setSelectedSubsector(subSectorName);
            }

        } catch (error) {
            console.error('Error fetching sector data:', error);
        }
    };

    // Handle selection of a sector
    const handleSectorChange = (event) => {
        const sectorId = event.target.value;
        setSelectedSector(sectorId);
        setFormData({ ...formData, sector: sectorId });

        const sector = sectors.find(s => s.id === parseInt(sectorId));
        if (sector) {
            setSubsectors(sector.children);
        }
    };

    const handleSubsectorChange = (event) => {
        const subsectorId = event.target.value;
        setSelectedSubsector(subsectorId); // Set the selected subsector state
        setFormData({ ...formData, sub_sector: subsectorId }); // Update formData.sub_sector
    };

    const handleStageChange = (event) => {
        const stageValue = event.target.value;
        setShowTender(stageValue === 'Proposal');
        setFormData({ ...formData, stage: stageValue }); // Update formData.sub_sector
    };

    const fetchCountryStateList = () => {
        if (countryData && countryData.countries) {
            const list = countryData.countries.flatMap((country) =>
                country.states ? country.states.map((state) => `${state}, ${country.country}`) : []
            );
            setStateCountryList(list);
        }
    }

    const getSectorName = (sectorId) => {
        const sector = sectors.find(sector => String(sector.id) === String(sectorId));
        return sector ? sector.name : '';
    };
    useEffect(() => {
        fetchCountryStateList();
        fetchData();
    }, [slug, token]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ToastContainer />

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <form onSubmit={handleSubmit}>
                            {!proceedClicked ?
                                <div className="card">
                                    <div className='p-3'>
                                        <button className='btn btn-dark float-end' onClick={() => navigate(-1)}>Back</button>
                                        {formData?.is_approved === 0 ? (
                                            <button type="button" className="btn btn-success float-end me-2" onClick={() => handleApprove(formData.id, 'publish')}>Publish</button>

                                        ) : (
                                            <button type="button" className="btn btn-secondary float-end me-2" onClick={() => handleApprove(formData.id, 'draft')}>Draft</button>
                                        )}
                                        <button className='btn btn-danger float-end me-2' onClick={() => handleDelete(formData.id)}>Delete</button>
                                    </div>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-end mb-3">
                                        </div>
                                        <div className='row mt-3'>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="name">Project Name <span className='text-danger'>*</span></label>
                                                <input type="text" className="form-control mt-2" id="name" name="name" value={formData?.name} onChange={handleInputChange} required />
                                                {errors.name && <div className="text-danger fs-6 mt-2">{errors.name}</div>}

                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="category">Category <span className='text-danger'>*</span></label>
                                                <select className="form-control mt-2" id="category" name="category" value={formData?.category} onChange={handleInputChange} required>
                                                    <option value="">Select Category</option>
                                                    <option value='Goods'>Goods</option>
                                                    <option value='Works'>Works</option>
                                                    <option value='Consulting Services'>Consulting Services</option>
                                                    <option value='Other Non-Consulting Services'>Other Non-Consulting Services</option>
                                                </select>
                                                {errors.category && <div className="text-danger fs-6 mt-2">{errors.category}</div>}

                                            </div>

                                            <div className="form-group col-md-6 mt-4">
                                                <label htmlFor="location">Location <span className='text-danger'>*</span></label>
                                                <input type="text" className="form-control mt-2" id="location" name="location" value={formData?.location} onChange={handleInputChange} required />
                                                {/* <Autocomplete
                          options={stateCountryList}
                          freeSolo
                          className={`mt-2 ${locationError ? 'is-invalid' : ''}`}
                          value={formData?.location}
                          required
                          name="location"
                          onChange={(event, newValue) => setSelectedCurrentLocation(newValue)}
                          renderInput={(params) => <TextField {...params} label="" />}
                        /> */}
                                                {errors.location && <div className="text-danger fs-6 mt-2">{errors.location}</div>}

                                            </div>
                                            <div className="form-group col-md-6  mt-4">
                                                <label htmlFor="stage">Project Stage <span className='text-danger'>*</span></label>
                                                <select className="form-control mt-2" id="stage" name="stage" value={formData?.stage} onChange={handleStageChange} required>
                                                    <option value="">Select Project Stage</option>
                                                    <option value="Current">Current</option>
                                                    <option value="Proposal">Proposal</option>
                                                </select>
                                                {errors.stage && <div className="text-danger fs-6 mt-2">{errors.stage}</div>}
                                            </div>
                                            <div className="form-group col-md-6  mt-4">
                                                <label htmlFor="sector">Sector <span className='text-danger'>*</span></label>
                                                <select className="form-control mt-2" id="sector" name="sector" value={formData?.sector} onChange={handleSectorChange} required>
                                                    <option value="">Select Sector</option>
                                                    {sectors.map(sector => (
                                                        <option key={sector.id} value={sector.id}>{sector.name}</option>
                                                    ))}
                                                </select>
                                                {errors.sector && <div className="text-danger fs-6 mt-2">{errors.sector}</div>}
                                            </div>

                                            <div className="form-group col-md-6  mt-4">
                                                <label htmlFor="sub_sector">Sub Sector <span className='text-danger'>*</span></label>
                                                <select className="form-control mt-2" id="sub_sector" name="sub_sector" value={formData?.sub_sector} onChange={handleSubsectorChange} required>
                                                    <option value="">Select Sub Sector</option>
                                                    {subsectors.map(subsector => (
                                                        <option key={subsector.id} value={subsector.name}>{subsector.name}</option>
                                                    ))}
                                                </select>
                                                {errors.sub_sector && <div className="text-danger fs-6 mt-2">{errors.sub_sector}</div>}
                                            </div>

                                            {showTender && (
                                                <div className="form-group col-md-6 mt-4">
                                                    <label htmlFor="tender_reference_number">Tender reference number <span className='text-danger'>*</span></label>
                                                    <input type="text" className="form-control mt-2" id="tender_reference_number" name="tender_reference_number" value={formData?.tender_reference_number} onChange={handleInputChange} required />
                                                    {errors.tender_reference_number && <div className="text-danger fs-6 mt-2">{errors.tender_reference_number}</div>}
                                                </div>
                                            )}

                                            <div className="form-group col-md-6 mt-4">
                                                <label htmlFor="budget">Project Cost <span className='text-danger'>*</span></label>
                                                <input type="text" className="form-control mt-2" id="budget" name="budget" value={formData?.budget} onChange={handleInputChange} required />
                                                {errors.budget && <div className="text-danger fs-6 mt-2">{errors.budget}</div>}
                                            </div>

                                            <div className="form-group col-md-6 mt-4">
                                                <label htmlFor="deadline">Deadline <span className='text-danger'>*</span></label>
                                                <input type="date" className="form-control mt-2" id="deadline" name="deadline"  value={formData?.deadline} onChange={handleInputChange} required />
                                                {errors.deadline && <div className="text-danger fs-6 mt-2">{errors.deadline}</div>}

                                            </div>


                                            {/* <div className="form-group col-md-6 mt-4">
                          <label htmlFor="type">Upload Image (JPG/JPEG/PNG/PDF only & max 2mb)</label>
                          <input type="file" className="form-control mt-2" id="jd_image" name="jd_image" accept="image/*" onChange={handleInputChange} />
                        </div> */}
                                            {formData?.features && (
                                                <div className="form-group col-md-12 mt-4">
                                                    <label htmlFor="floatingTextarea2">Features <span className='text-danger'>*</span></label>
                                                    <div className="form-floating h-100">
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={formData?.features}
                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                setFormData({ ...formData, features: data });
                                                            }}
                                                            required

                                                        />
                                                    </div>
                                                    {errors.features && <div className="text-danger fs-6 mt-2">{errors.features}</div>}
                                                </div>

                                            )}

                                        </div>
                                        {/* <div className="form-group col-md-6 mt-4">
                        <label htmlFor="location">Select key personnel type <span className='text-danger'>*</span></label>
                        <select className="form-select form-select-lg" name='key_personnel_type' value={formData?.key_personnel_type} onChange={handleInputChange} required>
                          <option value="National">National</option>
                          <option value="International">International</option>
                          <option value="Both">Both</option>
                        </select>
                      </div> */}
                                        {/* <div className='row mt-4'>
                          <h3 className='text-center'>Personnel Details </h3>
                          {formData?.key_personnel_details?.map((personnel, index) => (
                            <div className='col-md-6' key={index}>
                              <div className='card mt-4 shadow mb-5 bg-white rounded'>
                                <div className="mt-4 px-4">
                                  {index !== 0 && (
                                    <button
                                      type="button"
                                      className="btn btn-outline-danger w-20 float-end"
                                      onClick={() => removePersonnelField(index)}
                                    >
                                      Remove
                                    </button>
                                  )}
                                </div>
                                <div className="card-body">
                                  <div className={index === 0 ? 'row mt-4 py-2' : 'row'}>
                                    <div className="form-group col-md-6 mt-4">
                                      <label htmlFor="location">Select key personnel type <span className='text-danger'>*</span></label>
                                      <select
                                        className="form-select form-select-lg mt-2"
                                        name='key_personnel_type'
                                        value={personnel?.key_personnel_type}
                                        onChange={(e) => handlePersonnelChange(index, 'key_personnel_type', e.target.value)}
                                        required
                                      >
                                        <option value="National">National</option>
                                        <option value="International">International</option>
                                        <option value="Both">Both</option>
                                      </select>
                                    </div>
                                    <div className="form-group col-md-6 mt-4">
                                      <label>Personnel Position <span className='text-danger'>*</span></label>
                                      <div className="input-group">
                                        <input
                                          type="text"
                                          className="form-control mt-2 p-2"
                                          value={personnel?.name}
                                          onChange={(e) => handlePersonnelChange(index, 'name', e.target.value)}
                                          required
                                        />
                                      </div>
                                    </div>
                                    {personnel?.description && (
                                      <div className="form-group col-md-12 mt-4 mb-3">
                                        <label htmlFor="floatingTextarea2">Description <span className='text-danger'>*</span></label>
                                        <div className="form-floating h-50 mt-2">
                                          <CKEditor
                                            editor={ClassicEditor}
                                            data={personnel?.description}
                                            onChange={(event, editor) => {
                                              const data = editor.getData();
                                              handlePersonnelChange(index, 'description', data);
                                            }}
                                            required
                                          />
                                        </div>
                                      </div>
                                    )}

                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className="col-md-12 mt-2">
                            <button type="button" className="btn btn-secondary float-end" onClick={addPersonnelField}>Add More Postition</button>
                          </div>
                        </div> */}


                                        <div className="text-center mt-5 mb-4">
                                            <button type="button" className="btn btn-primary" onClick={addProject}>Next</button>
                                        </div>

                                    </div>
                                </div>
                                :
                                <>
                                    {/* New position structure */}
                                    <div className="card mb-4 mt-3">
                                        <div className="card-header d-flex justify-content-between align-items-center">Personnel Details
                                        </div>
                                        <div className="card-body">
                                            <div className="accordion mt-4" id="accordionExample">
                                                {formData?.key_personnel_details.map((personnel, index) => (
                                                    <div className="accordion-item mt-4" key={index}>
                                                        <div className="accordion-header" id={`heading${index}`}>
                                                            <button
                                                                className="accordion-button d-flex justify-content-between align-items-center"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target={`#collapse${index}`}
                                                                aria-expanded="true"
                                                                aria-controls={`collapse${index}`}
                                                            >
                                                                <span>
                                                                    Personnel {index + 1}
                                                                </span>

                                                                {index !== 0 && (
                                                                    <div
                                                                        className="cursor-pointer bg-white rounded-circle d-flex justify-content-center align-items-center mx-3"
                                                                        style={{ width: '40px', height: '40px' }}
                                                                        onClick={() => removePersonnelField(index)}
                                                                    >
                                                                        <i
                                                                            className="material-icons text-danger"
                                                                            style={{ cursor: 'pointer', color: 'red' }}
                                                                            title="Delete"
                                                                        >
                                                                            delete
                                                                        </i>
                                                                    </div>
                                                                )}

                                                            </button>



                                                        </div>
                                                        <div
                                                            id={`collapse${index}`}
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby={`heading${index}`}
                                                            data-bs-parent="#accordionExample"
                                                        >
                                                            <div className="accordion-body">
                                                                <div className="row">
                                                                    <div className='row mt-3'>
                                                                        <div className='col-md-6'>
                                                                            <div className="form-group mt-2">
                                                                                <label htmlFor={`title-${index}`}>Job Title <span className='text-danger'>*</span></label>
                                                                                <input type="text" className="form-control mt-2" id={`title-${index}`} name="title" value={personnel.title} onChange={(e) => handlePersonnelChange(index, 'title', e.target.value)} required />
                                                                                {errors.key_personnel_details && errors.key_personnel_details.title && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.title}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-md-6'>
                                                                            <div className="form-group mt-2">
                                                                                <label htmlFor={`location-${index}`}>Location <span className='text-danger'>*</span></label>
                                                                                <input type="text" className="form-control mt-2" id={`location-${index}`} name="location" value={formData.location} onChange={(e) => handlePersonnelChange(index, 'location', e.target.value)} required disabled />
                                                                                {errors.key_personnel_details && errors.key_personnel_details.location && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.location}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-md-6'>
                                                                            <div className="form-group mt-2">
                                                                                <label htmlFor={`language-${index}`}>Languages <span className='text-danger'>*</span></label>
                                                                                <input type="text" className="form-control mt-2" id={`language-${index}`} name="language" value={personnel.language} onChange={(e) => handlePersonnelChange(index, 'language', e.target.value)} required />
                                                                                {errors.key_personnel_details && errors.key_personnel_details.language && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.language}</div>}
                                                                            </div>
                                                                        </div>

                                                                        <div className='col-md-6'>
                                                                            <div className="form-group mt-2">
                                                                                <label htmlFor={`stage-${index}`}>Job Stage <span className='text-danger'>*</span></label>
                                                                                <input type="text" className="form-control mt-2" id={`stage-${index}`} name="stage" value={formData.stage} required disabled />

                                                                                {/* <select className="form-control mt-2" id={`stage-${index}`} name="stage" value={formData.stage} onChange={(e) => handlePersonnelChange(index, 'stage', e.target.value)} required>
                                              <option value="">Select Job Stage</option>
                                              <option value="Current">Current</option>
                                             <option value="Proposal">Proposal</option>
                                            </select> */}
                                                                                {errors.key_personnel_details && errors.key_personnel_details.stage && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.stage}</div>}
                                                                            </div>
                                                                        </div>


                                                                        <div className='col-md-6'>
                                                                            <div className="form-group mt-2">
                                                                                <label htmlFor={`sector-${index}`}>Sector <span className='text-danger'>*</span></label>
                                                                                <input type="text" className="form-control mt-2" id={`sector-${index}`} name="sector" value={getSectorName(formData.sector)} required disabled />
                                                                                {/* <select className="form-control mt-2" id={`sector-${index}`} name="sector" value={formData.sector} onChange={(e) => handlePersonnelChange(index, 'sector', e.target.value)} required readonly>
                                              <option value="">Select Sector</option>
                                              {sectors.map(sector => (
                                                <option key={sector.id} value={sector.id}>{sector.name}</option>
                                              ))}
                                            </select> */}
                                                                                {errors.key_personnel_details && errors.key_personnel_details.sector && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.sector}</div>}
                                                                            </div>
                                                                        </div>

                                                                        <div className='col-md-6'>
                                                                            <div className="form-group mt-2">
                                                                                <label htmlFor={`sub_sector-${index}`}>Sub Sector <span className='text-danger'>*</span></label>
                                                                                <input type="text" className="form-control mt-2" id={`sub_sector-${index}`} name="sub_sector" value={formData.sub_sector} required disabled />
                                                                                {/* <select className="form-control mt-2" id={`sub_sector-${index}`} name="sub_sector" value={formData.sub_sector} onChange={(e) => handlePersonnelChange(index, 'sub_sector', e.target.value)} required readonly>
                                              <option value="">Select Sub Sector</option>
                                              {subsectors.map(subsector => (
                                                <option key={subsector.id} value={subsector.id}>{subsector.name}</option>
                                              ))}
                                            </select> */}
                                                                                {errors.key_personnel_details && errors.key_personnel_details.sub_sector && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.sub_sector}</div>}
                                                                            </div>
                                                                        </div>


                                                                        <div className='col-md-6'>
                                                                            <div className="form-group mt-2">
                                                                                <label htmlFor={`category-${index}`}>Category <span className='text-danger'>*</span></label>
                                                                                <input type="text" className="form-control mt-2" id={`category-${index}`} name="category" value={formData.category} required disabled />
                                                                                {/* <select className="form-control mt-2" id={`category-${index}`} name="category" value={formData.category} onChange={(e) => handlePersonnelChange(index, 'category', e.target.value)} required readonly>
                                              <option value="">Select Category</option>
                                              <option value="Goods">Goods</option>
                                              <option value="Works">Works</option>
                                              <option value="Consulting Services">Consulting Services</option>
                                              <option value="Other Non-Consulting Services">Other Non-Consulting Services</option>
                                            </select> */}
                                                                                {errors.key_personnel_details && errors.key_personnel_details.category && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.category}</div>}
                                                                            </div>
                                                                        </div>

                                                                        <div className='col-md-6'>
                                                                            <div className="form-group mt-2">
                                                                                <label htmlFor={`job_type-${index}`}>Job Type <span className='text-danger'>*</span></label>
                                                                                <select className="form-control mt-2" id={`job_type-${index}`} name="job_type" value={personnel.job_type} onChange={(e) => handlePersonnelChange(index, 'job_type', e.target.value)} required>
                                                                                    <option value="">Select Job Type</option>
                                                                                    <option value="Full-time">Full-time</option>
                                                                                    <option value="Part-time">Part-time</option>
                                                                                    <option value="Contract">Contract</option>
                                                                                    <option value="Permanent">Permanent</option>
                                                                                    <option value="Temporary">Temporary</option>
                                                                                    <option value="Seasonal">Seasonal</option>
                                                                                    <option value="Intermittent">Intermittent</option>
                                                                                    <option value="Internship">Internship</option>

                                                                                </select>
                                                                                {errors.key_personnel_details && errors.key_personnel_details.job_type && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.job_type}</div>}
                                                                            </div>
                                                                        </div>

                                                                        <div className='col-md-6'>
                                                                            <div className="form-group mt-2">
                                                                                <label htmlFor={`experience-${index}`}>Experience <span className='text-danger'>*</span></label>
                                                                                <input type="text" className="form-control mt-2" id={`experience-${index}`} name="experience" value={personnel.experience} onChange={(e) => handlePersonnelChange(index, 'experience', e.target.value)} required />
                                                                                {errors.key_personnel_details && errors.key_personnel_details.experience && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.experience}</div>}
                                                                            </div>
                                                                        </div>

                                                                        <div className='col-md-6'>
                                                                            <div className="form-group mt-2">
                                                                                <label htmlFor={`deadline-${index}`}>Deadline <span className='text-danger'>*</span></label>
                                                                                <input type="date" className="form-control mt-2" id={`deadline-${index}`} name="deadline" value={personnel.deadline} onChange={(e) => handlePersonnelChange(index, 'deadline', e.target.value)}  required />
                                                                                {errors.key_personnel_details && errors.key_personnel_details.deadline && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.deadline}</div>}
                                                                            </div>
                                                                        </div>

                                                                        <div className='col-md-6'>
                                                                            <div className="form-group mt-2">
                                                                                <label htmlFor={`personnel_type-${index}`}>Personnel Type <span className='text-danger'>*</span></label>
                                                                                <select className="form-control mt-2" id={`personnel_type-${index}`} name="personnel_type" value={personnel.personnel_type} onChange={(e) => handlePersonnelChange(index, 'personnel_type', e.target.value)} required>
                                                                                    <option value="">Select Personnel Type</option>
                                                                                    <option value="National">National</option>
                                                                                    <option value="International">International</option>
                                                                                </select>
                                                                                {errors.key_personnel_details && errors.key_personnel_details.personnel_type && <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.personnel_type}</div>}
                                                                            </div>
                                                                        </div>


                                                                        <div className='col-md-6'>
                                                                            <div className="form-group mt-2">
                                                                                <label htmlFor={`salary-${index}`}>Salary</label>
                                                                                <input type="text" className="form-control mt-2" id={`salary-${index}`} name="salary" value={personnel.salary} onChange={(e) => handlePersonnelChange(index, 'salary', e.target.value)} />
                                                                            </div>
                                                                        </div>

                                                                        <div className='col-md-12'>
                                                                            <div className="form-group mt-2">
                                                                                <label htmlFor={`description-${index}`}>Description <span className='text-danger'>*</span></label>
                                                                                <CKEditor
                                                                                    editor={ClassicEditor}
                                                                                    data={personnel.description}
                                                                                    onChange={(event, editor) => {
                                                                                        const data = editor.getData();
                                                                                        handlePersonnelChange(index, 'description', data);
                                                                                    }}
                                                                                />
                                                                                {errors.key_personnel_details && errors.key_personnel_details.description && (
                                                                                    <div className="text-danger fs-6 mt-2">{errors.key_personnel_details.description}</div>
                                                                                )}
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="d-flex justify-content-end mt-3">
                                                <button type="button" className="btn btn-primary" onClick={addPersonnelField}>
                                                    Add More Personnel
                                                </button>
                                            </div>

                                        </div>

                                    </div>


                                    {/* End new position structure */}
                                    <div className="text-center mt-5 mb-4">
                                        <button type="button" className="btn btn-dark mx-2" onClick={() => setProceedClicked(false)}>Back</button>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </>
                            }
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </DashboardLayout>
    );
};

export default Edit;
