import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import MDInput from "components/MDInput";
import MDBox from 'components/MDBox';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Delete, Edit } from '@mui/icons-material';


// const months = [
//     "January", "February", "March", "April",
//     "May", "June", "July", "August",
//     "September", "October", "November", "December"
// ];
const EditExperience = ({ data, user, token, handleDataFetch }) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const [experience, setExperience] = useState([]);
    const [showExperienceModal, setShowExperienceModal] = useState(false);
    const [selectedExperience, setSelectedExperience] = useState(''); // State to hold the selected experience for editing
    const [error, setError] = useState(false);
    const [dateError, setDateError] = useState('');
    const [showFullDescription, setShowFullDescription] = useState(false);
    const currentDate = new Date();
    // Extract the current year and month
    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits for month

    // Format the end date
    const endDate = `${currentYear}-${currentMonth}`;
    // Format the max date
    const maxDate = `${currentYear}-${currentMonth}`;

    const initialState = {
        title: '',
        employment_type: '',
        company_name: '',
        location: '',
        description: '',
        currently_working: true,
        start_date: '2024-01',
        end_date: endDate,
        isPresent: true
    };

    const [formData, setFormData] = useState(initialState);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("selectedExperience", selectedExperience)

        // Construct new experience object
        const newExperience = {
            title: formData.title,
            employment_type: formData.employment_type,
            company_name: formData.company_name,
            location: formData.location,
            description: formData.description,
            start_date: formData.start_date,
            end_date: formData.end_date,
            isPresent: formData.isPresent,
            id: selectedExperience
        };

        try {
            const id = data?.user?.id;
            // Make the API call to update experience details
            const response = await axios.post(`${apiUrl}api/professional/add-experience/${id}`, { experience: [newExperience] }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            toast.success("Experience details saved successfully !!", {
                className: "toast-message",
            });
            // Optionally, you can update the UI or show a success message here

            // Update experience array with new experience
            setExperience([...experience, newExperience]);
            setSelectedExperience('')

            // Close modal
            setShowExperienceModal(false);
            handleDataFetch();

        } catch (error) {
            console.error('Error updating experience details:', error);
        }
    }


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        const isPresentValue = name === 'currently_working' ? checked : formData.isPresent;

        setFormData({ ...formData, [name]: newValue, isPresent: isPresentValue });
        let errorMessage = '';
        if (name === 'start_date' || name === 'end_date') {
            const startDate = name === 'start_date' ? new Date(value) : new Date(formData.start_date);
            const endDate = name === 'end_date' ? new Date(value) : new Date(formData.end_date);
            const currentDate = new Date();

            if (startDate > currentDate) {
                setError(true);
                errorMessage = 'Start date cannot be in the future.';
                setDateError(errorMessage)
            }
            else if (endDate > currentDate) {
                setError(true);
                errorMessage = 'End date cannot be in the future.';
                setDateError(errorMessage)
            } else if (startDate > endDate) {
                setError(true);
                errorMessage = 'Start date should not be after end date.';
                setDateError(errorMessage)
            }
            else {
                errorMessage = '';
                setError(false);
            }
            console.log("errorMessage", errorMessage);
        }

    }
    function convertDateFormat(dateString) {
        if (dateString) {
            const dateParts = dateString.split('-');
            const year = dateParts[0];
            const month = new Date(dateString + '-01').toLocaleString('en-us', { month: 'short' });
            return `${month} ${year}`;
        }

    }

    const toggleFeatures = () => {
        setShowFullDescription(!showFullDescription);
    };

    const openAddModal = () => {
        setFormData(initialState);
        setShowExperienceModal(true);

    }

    const handleEdit = (index) => {
        setSelectedExperience(index);
        setFormData({ ...experience[index] });
        setShowExperienceModal(true);
    }

    const handleDelete = (index) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this experience?");
        if (confirmDelete) {
            deleteExperience(index);
        }
    }

    const deleteExperience = async (experienceId) => {
        const id = data?.user?.id;
        try {
            const response = await axios.delete(`${apiUrl}api/professional/delete-experience/${id}/${experienceId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            // Handle success response
            toast.success("Experience details deleted successfully !!", {
                className: "toast-message",
            });

            // After successful deletion, you might want to update the UI or refetch data
            handleDataFetch();
        } catch (error) {
            console.error('Something went wrong:', error);
            // Handle error response
            toast.error("Something went wrong. Please try again later.", {
                className: "toast-message",
            });
        }
    }


    useEffect(() => {
        setExperience(data?.details?.experience);
    }, [data]);

    return (
        <div className="card mb-4 mt-3">
            {/* Card Header */}
            <div className="card-header d-flex justify-content-between align-items-center">
                <h5>Experience</h5>
                {data?.user?.id == user?.user?.id && (
                    <div className="cursor-pointer" onClick={openAddModal}>
                        <i className="material-icons" style={{ cursor: 'pointer' }} title="Add">add</i>
                    </div>
                )}
            </div>

            {/* Card Body */}
            {data?.details?.experience && data?.details?.experience.length > 0? (

                <div className="card-body">
                    <div className="accordion" id="accordionExample">
                        {data?.details?.experience.map((exp, index) => (
                            <div className="accordion-item" key={index}>
                                <h2 className="accordion-header" id={`heading${index}`}>
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                        {exp.title} <span className='px-1'> - {exp?.company_name}</span> <span className='px-1'> ({convertDateFormat(exp?.start_date)} - {endDate === exp.end_date ? 'Present' : convertDateFormat(exp?.end_date)})</span>
                                    </button>

                                </h2>
                                <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className='meta-info'>
                                            <h5 className='text-bold'>{exp.title}</h5>
                                            <h6>{convertDateFormat(exp?.start_date)} - {endDate === exp.end_date ? 'Present' : convertDateFormat(exp?.end_date)}  {exp?.employment_type && (` (${exp?.employment_type})`)}</h6>
                                            <p><strong>Company Name: </strong><span>{exp?.company_name}</span></p>
                                            <p><strong>Location: </strong><span>{exp?.location}</span></p>
                                            {exp?.description && (
                                                <p>
                                                    {showFullDescription ?
                                                        <span dangerouslySetInnerHTML={{ __html: exp?.description }} /> :
                                                        <span dangerouslySetInnerHTML={{ __html: exp?.description.substring(0, 200) + "..." }} />
                                                    }
                                                        {exp?.description.length > 200 && (
                                                            <span className="text-secondary" style={{ cursor: 'pointer' }} onClick={toggleFeatures}>
                                                                {showFullDescription ? " View less" : " View more"}
                                                            </span>
                                                        )}
                                                    
                                                </p>
                                            )}
                                        </div>
                                        <div className='buttons'>
                                            <span className="edit-btn" onClick={() => handleEdit(index)}>
                                                <span class="material-symbols-outlined"> edit </span>
                                            </span>
                                            <span className="delete-btn" onClick={() => handleDelete(index)}>
                                                <span class="material-symbols-outlined"> delete </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>

            ) : (
                <div className="card-body">
                    <p className='text-muted'>
                        <small>Share your professional journey and expertise by adding experience. Highlight your career milestones, skills, and accomplishments to stand out to potential employers.</small>
                    </p>
                </div>
            )}

            {/* Modal */}
            <div className={`modal ${showExperienceModal ? 'show' : ''}`} style={{ display: showExperienceModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add experience</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowExperienceModal(false)}>
                                <span aria-hidden="true" className='p-2'>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body w-100 h-100">
                            <form onSubmit={handleSubmit}>
                                {/* Form Fields */}
                                <div className="form-group">
                                    <label htmlFor="title">Title <span className='text-danger'>*</span></label>
                                    <input type="text" className="form-control" placeholder="Ex: Project manager" id="title" name="title" required onChange={handleChange} value={formData.title} />
                                </div>
                                <div className="form-group mt-4">
                                    <label htmlFor="employment_type">Employment type</label>
                                    <select className="form-control mt-2" id="type" name="employment_type" onChange={handleChange} value={formData.employment_type}>
                                        <option value="">Select Employment type</option>
                                        <option value="Full-time">Full-time</option>
                                        <option value="Part-time">Part-time</option>
                                        <option value="Interniship">Interniship</option>
                                        <option value="Trainee">Trainee</option>
                                    </select>
                                </div>

                                <div className="form-group mt-4">
                                    <label htmlFor="company_name">Company Name <span className='text-danger'>*</span></label>
                                    <input type="text" className="form-control" id="company_name" name="company_name" required onChange={handleChange} value={formData.company_name} />
                                </div>

                                <div className="form-group mt-4">
                                    <label htmlFor="location">Location <span className='text-danger'>*</span></label>
                                    <input type="text" className="form-control" id="location" name="location" placeholder='City, State, Country' onChange={handleChange} value={formData.location} />
                                </div>

                                <div className="form-group mt-4">
                                    <input className="form-check-input" type="checkbox" id="flexCheckDefault" name="currently_working" checked={formData.currently_working} onChange={handleChange} />
                                    <label className="form-check-label px-2" htmlFor="flexCheckDefault">
                                        I am currently working in this role
                                    </label>
                                    <div className='row mt-4 mb-4'>
                                        <div className='col-md-6'>
                                            <label htmlFor="start_date" className="form-label">Start Date <span className='text-danger'>*</span></label>
                                            <MDInput type="month" id="start_date" name="start_date" onChange={handleChange} value={formData.start_date} required />
                                        </div>
                                        {formData.currently_working ?
                                            <div className='col-md-6'>
                                                <label htmlFor="end_date" className="form-label">End Date {!formData.currently_working && (<span className='text-danger'>*</span>)}</label>
                                                <MDInput type="text" id="end_date" name="end_date" onChange={handleChange} value='Present' disabled={formData.currently_working} />
                                            </div>
                                            :
                                            <div className='col-md-6'>
                                                <label htmlFor="end_date" className="form-label">End Date {!formData.currently_working && (<span className='text-danger'>*</span>)}</label>
                                                <MDInput type="month" id="end_date" name="end_date" onChange={handleChange} value={formData.end_date} disabled={formData.currently_working} required={!formData.currently_working} />
                                            </div>

                                        }

                                        {error && dateError && <div className="text-danger mt-2"><small>{dateError}</small></div>}
                                    </div>


                                </div>

                                <div className="form-group mt-4 mb-4">
                                    <label htmlFor="description">Description</label>
                                    <textarea className="form-control" style={{ height: '100px' }} name='description' onChange={handleChange} value={formData?.description}></textarea>
                                    <div className="text-sm-end text-muted"><small>0/500 characters</small></div>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShowExperienceModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Save changes</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditExperience;
