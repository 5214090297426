import React, { useState } from 'react';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import { ToastContainer, toast } from 'react-toastify';
import { Grid, CircularProgress, Button } from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import contactImage from "../../assets/images/data/contact.jpg";
import icon1 from "../../assets/images/data/icon1.png";
import icon2 from "../../assets/images/data/icon2.png";
import icon3 from "../../assets/images/data/icon3.png";

// Initial form data for your form
const initialFormData = {
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
};

const Index = () => {
    const [formData, setFormData] = useState(initialFormData);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const { token } = useSelector(selectAuth);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevents default form submission
        setLoading(true); // Set loading to true

        try {
            const response = await axios.post(`${apiUrl}api/submit-contact-us`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                toast.success('Your message has been sent to the Admin.');
                setFormData(initialFormData); // Reset form data
            } else {
                toast.error('Something went wrong. Please try again later.');
            }
        } catch (error) {
            console.error('Error submitting contact form:', error);
            toast.error('Error submitting the form. Please try again.');
        } finally {
            setLoading(false); // Set loading to false
        }
    };

    return (
        <BasicLayout>
            <ToastContainer />
            {/* Spinner overlay */}
            {loading && (
                <div className="spinner-overlay">
                    <CircularProgress />
                </div>
            )}
            <Grid container justifyContent="center" alignItems="center">

                <div className={loading ? 'blurred-background' : ''}>
                    <div className="container py-5 mt-5">
                        <div className="row">
                            <div>
                                <h2 className="section-title text-primary mb-3 mb-sm-4 mt-3">Frequently Asked Questions (FAQ)</h2>
                                <div className="accordion" id="accordionExample">

                                    <div className="accordion-item mt-2">
                                        <h2 className="accordion-header" id="heading1">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                                                1. Getting Started
                                            </button>
                                        </h2>
                                        <div id="collapse1" className="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <h5 className="text-bold">What is Proxtal?</h5>
                                                <p>Proxtal is an online platform that facilitates connections between international development professionals and various projects worldwide. Whether you’re a professional, contractor, consultant, researcher, or practitioner, Proxtal provides a space to collaborate, share expertise, and contribute to impactful projects.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mt-2">
                                        <h2 className="accordion-header" id="heading2">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                                2. My Account
                                            </button>
                                        </h2>
                                        <div id="collapse2" className="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <ol type='a'>
                                                    <li className="text-bold">How do I sign up for Proxtal?</li>
                                                    <p>To join Proxtal, visit our website and click on the “Sign Up” button. You’ll need to provide relevant information about your background, skills, and interests. Once registered, you can explore available projects and connect with other professionals.</p>
                                                    <li className="text-bold">Forgot Username or Password?</li>
                                                    <ul>

                                                    </ul>
                                                    <p>Click ‘Forgot Username’ or ‘Forgot Password’ on the sign-in page. Follow the instructions sent to your registered email address.</p>
                                                    <li className="text-bold">Updating Personal Information</li>
                                                    <p>Your username is unique and cannot be changed. To change your password, go to ‘My Settings’ in the portal.</p>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mt-2">
                                        <h2 className="accordion-header" id="heading3">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                                3. Posting a Job or Project in Proxtal
                                            </button>
                                        </h2>
                                        <div id="collapse3" className="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <h5 className="text-bold">Log in to Your Account</h5>
                                                <p>Sign in to your Proxtal account using your credentials.</p>
                                                <h5 className="text-bold">Navigate to Job or Project Postings</h5>
                                                <p>Click on the “Jobs or Project” section from the left navigation bar.</p>
                                                <h5 className="text-bold">Create a New Job or Project Posting</h5>
                                                <p>In the upper-right corner of the page, click the black button that says “Add Job” or “Add Project”.</p>
                                                <h5 className="text-bold">Complete the Job or Project Form</h5>
                                                <p>Fill out the necessary details for your job posting. Include relevant information about the role, responsibilities, qualifications, and any other specifics.</p>
                                                <h5 className="text-bold">Submit Your Job or Project Posting</h5>
                                                <p>Once you’ve filled in the required fields, submit your job posting. It will then be visible to potential candidates on Proxtal.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mt-2">
                                        <h2 className="accordion-header" id="heading4">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                                4. Search Job or Project in Proxtal
                                            </button>
                                        </h2>
                                        <div id="collapse4" className="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <h5 className="text-bold">What types of projects are listed on Proxtal?</h5>
                                                <p>Proxtal hosts a diverse range of projects related to Infrastructure, Water, Railway, Roads & Bridge, sustainable development, education, healthcare, and more. Projects can be short-term or long-term, and they span various sectors and regions.</p>
                                                <h5 className="text-bold">What happens after I express interest in a project?</h5>
                                                <p>When you express interest in a project, the organization or manager will review your profile. If they find a match, they’ll reach out to discuss further details. Be sure to keep your profile updated and respond promptly to inquiries.</p>
                                                <h5 className="text-bold">How can I search for Jobs or projects on Proxtal?</h5>
                                                <p>Use the search filters on our platform to narrow down jobs or projects based on criteria such as location, expertise required, project duration, and focus area. You can also set notifications to receive updates on new job or project listings.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mt-2">
                                        <h2 className="accordion-header" id="heading5">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                                5. Showcase your Portfolio
                                            </button>
                                        </h2>
                                        <div id="collapse5" className="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <h5 className="text-bold">How can I showcase my portfolio on Proxtal?</h5>
                                                <p>Professionals can upload relevant documents, such as your CV, project reports, or case studies, to your Proxtal profile. This helps potential collaborators understand your expertise and track record. Organizations, please ensure that you update your profiles to gain more insights from professionals.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mt-2">
                                        <h2 className="accordion-header" id="heading6">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                                6. General
                                            </button>
                                        </h2>
                                        <div id="collapse6" className="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <h5 className="text-bold">Is Proxtal free to use?</h5>
                                                <p>Yes, Proxtal offers a completely free service for a limited period of time. These include enhanced project matching, priority access to new opportunities, and personalized support.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mt-2">
                                        <h2 className="accordion-header" id="heading7">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                                7. Data and Privacy Policy
                                            </button>
                                        </h2>
                                        <div id="collapse7" className="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <h5 className="text-bold">What privacy measures does Proxtal have in place?</h5>
                                                <p>Proxtal takes data privacy seriously. We adhere to strict privacy policies and ensure that user information is secure. You can find detailed information in our Privacy Policy section on the website.</p>
                                                <p>If your inquiry is about data and privacy-related queries, please email your query and request to <a href="mailto:gdpr@proxtal.com">gdpr@proxtal.com</a>.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item mt-2">
                                        <h2 className="accordion-header" id="heading8">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                                8. Support
                                            </button>
                                        </h2>
                                        <div id="collapse8" className="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <h5 className="text-bold">How can I report issues or seek support on Proxtal?</h5>
                                                <p>If you encounter any technical issues or need any assistance, you can email <a href="mailto:support@proxtal.com">support@proxtal.com</a>.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <h5 className='mt-5'>Contact Us</h5>
                            <p>If you still need help, send us a message using the form below and we'll get in touch with you as soon as possible.</p>

                            <div className="col-md-12 mt-3">
                                <div className="contact-box ms-3">
                                    <div className="card">
                                        <h1 className="font-weight-light p-3">Get In Touch</h1>
                                        <div className="card-body">
                                            <form onSubmit={handleSubmit}>
                                                <div className='row'>
                                                    <div className="form-group col-md-6 mb-4">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Name *"
                                                            name="name"
                                                            value={formData.name}
                                                            onChange={handleInputChange}
                                                            required
                                                            maxLength={100}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6 mb-4">
                                                        <input
                                                            className="form-control"
                                                            type="email"
                                                            placeholder="Email *"
                                                            name="email"
                                                            value={formData.email}
                                                            onChange={handleInputChange}
                                                            required
                                                            maxLength={100}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6 mb-4">
                                                        <input
                                                            className="form-control"
                                                            type="tel"
                                                            placeholder="Phone"
                                                            name="phone"
                                                            value={formData.phone}
                                                            onChange={handleInputChange}
                                                            maxLength={15}
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6 mb-4">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Subject *"
                                                            name="subject"
                                                            value={formData.subject}
                                                            onChange={handleInputChange}
                                                            required
                                                            maxLength={200}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-4">
                                                        <textarea
                                                            className="form-control"
                                                            rows="3"
                                                            placeholder="Message *"
                                                            name="message"
                                                            value={formData.message}
                                                            onChange={handleInputChange}
                                                            required
                                                            maxLength={500}
                                                        ></textarea>
                                                    </div>
                                                </div>

                                                <div>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary text-white float-end"
                                                        disabled={loading}
                                                    >
                                                        SUBMIT
                                                    </button>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Grid>
        </BasicLayout>
    );
};

export default Index;
