import React from 'react';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";
import {
    FacebookIcon,
    LinkedinIcon,
    TelegramIcon,
    WhatsappIcon,
    XIcon,
} from "react-share";
const ShowShareModal = ({ show, handleClose, copyURLToClipboard }) => {
    const shareUrl = window.location.href;

    return (
        <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog">

            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Share</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                            <span aria-hidden="true" className='p-2'>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body w-100 h-100">
                        <div className='d-flex justify-content-center'>
                            <FacebookShareButton url={shareUrl}>
                                <FacebookIcon size={50} round={true} />
                            </FacebookShareButton>

                            <WhatsappShareButton url={shareUrl} className='mx-3'>
                                <WhatsappIcon size={50} round={true} />
                            </WhatsappShareButton>

                            <LinkedinShareButton url={shareUrl} className=''>
                                <LinkedinIcon size={50} round={true} />
                            </LinkedinShareButton>

                            <TelegramShareButton url={shareUrl} className='mx-3'>
                                <TelegramIcon size={50} round={true} />
                            </TelegramShareButton>

                            <TwitterShareButton url={shareUrl} className=''>
                                <XIcon size={50} round={true} />
                            </TwitterShareButton>
                        </div>
                        <div className="input-group mb-3 mt-4">
                            <input type="text" className="form-control" aria-describedby="basic-addon2" defaultValue={shareUrl} />
                                <div className="input-group-append ">
                                    <span className="input-group-text bg-dark text-white" id="basic-addon2" style={{ cursor: 'pointer' }} onClick={copyURLToClipboard}>Copy</span>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShowShareModal;
