/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import SupportIcon from '@mui/icons-material/Support';


function DefaultNavbarLink({ icon, name, route, light }) {
  return (
    <>
    <li className="nav-item mx-2">
      <MDBox
        className='nav-link'
        component={Link}
        to={route}
      >
        {name === "Support" && <SupportIcon style={{ marginRight: '8px' }} />}
        {name}
         
      </MDBox>
    </li>
    </>
  );
}

// Typechecking props for the DefaultNavbarLink
DefaultNavbarLink.propTypes = {
  name: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
};

export default DefaultNavbarLink;
