import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

const UserInfo = (props) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';

    // Initialize state for form data
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
    });
    useEffect(() => {
        setFormData({
            name: props.data?.user?.name || '',
            email: props.data?.user?.email || '',
            phone: props.data?.user?.phone || '',
        });
    }, [props.data]);


    const handleInputChange = (e) => {

        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle form submission
    const handleUserInfoSubmit = async (e) => {
        e.preventDefault();
        try {
            const id = props.data?.user?.id;
            if (id) {
                const response = await axios.put(`${apiUrl}api/user-info-update/${id}`, formData, {
                    headers: {
                        'Authorization': `Bearer ${props.token}`,
                        'Content-Type': 'application/json',
                    }
                });
                console.log(response.data); // Handle success response
                toast.success("User Info saved successfully !!", {
                    className: "toast-message",
                });
            }
        } catch (error) {
            console.error('Error updating user info:', error); // Log error
            toast.error("Failed to save user info. Please try again later.", {
                className: "toast-message",
            });
        }
    };

    return (
        <div className="card mb-4 mt-3">
            <div className="card-header">
                <h5>User Info</h5>
            </div>
            <div className="card-body">
                <form onSubmit={handleUserInfoSubmit}>
                    <div className='row mt-3'>
                        <div className="form-group col-md-6">
                            <label htmlFor="name">User Name <span className='text-danger'>*</span></label>
                            <input type="text" className="form-control mt-2" id="name" name="name" value={formData.name} required onChange={handleInputChange} />
                        </div>

                        <div className="form-group col-md-6">
                            <label htmlFor="email">Email <span className='text-danger'>*</span></label>
                            <input type="text" className="form-control mt-2" id="email" name="email" value={formData.email} onChange={handleInputChange} />
                        </div>

                        <div className="form-group col-md-6 mt-3">
                            <label htmlFor="phone">Phone Number<span className='text-danger'>*</span></label>
                            <input type="text" className="form-control mt-2" id="phone" name="phone" value={formData.phone} required onChange={handleInputChange} />
                        </div>
                    </div>
                    <div className="text-center mt-5 mb-4">
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default UserInfo;
