import React, { useEffect, useState } from 'react';
import Footer from "examples/Footer";
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../../store/slices/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import { Grid } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';

const Index = () => {
  const [data, setData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { token, user } = useSelector(selectAuth);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      if (token) {
        const response = await fetch(`${apiUrl}api/projects`, {
          method: 'GET', // Adjust the HTTP method as needed
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        setData(result);
        console.log(result);
      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleApprove = async (projectId, message) => {
    // Show a confirmation dialog
    setShowAlert(false)
    const isConfirmed = window.confirm(message === 'publish' ? 'Are you sure you want to publish this project?' : 'Are you sure you want to draft this project?');

    if (isConfirmed) {
      try {
        const response = await fetch(`${apiUrl}api/project/${projectId}/update-status`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();

        if (response.ok) {
          fetchData();
          setShowAlert(true);
          console.log("result", result)
          toast.success(result?.message);


        } else {
          // Handle error, e.g., show an error message
          console.error('Failed to approve project');
        }
      } catch (error) {
        // Handle network error
        console.error('Network error:', error);
      }
    }
  };

  const handleDelete = async (projectId) => {
    const confirmed = window.confirm('Are you sure you want to delete this project?');
    if (!confirmed) return; // If user cancels, do nothing
    try {
      const response = await fetch(`${apiUrl}api/projects/${projectId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (response.ok) {
        setShowAlert(true);
        setAlertMessage('Project details deleted successfully');
        fetchData();
      } else {
        setShowAlert(true);
        setError(result.message || 'Failed to delete project');
      }
    } catch (error) {
      console.error('Error deleting project:', error);
      setShowAlert(true);
      setError('Failed to delete project. Please try again later.');
    }
  }
  useEffect(() => {
    fetchData();
  }, [token]);
  return (
    <BasicLayout>
      <ToastContainer />
      <div className='inner-banner'>
        <div className='container'>
          <h2>My Projects</h2>
        </div>
      </div>
      
      <Grid container justifyContent="center" alignItems="center">
          <div className="container my-projects-landingpage">
            <div className="row justify-content-center">
              
              {data?.map((project, index) => (
                <div className='col-md-12 py-3 px-3' key={index}>
                  <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center text-primary text-wrap">
                      <Link to={`/project/${project?.slug}`}>
                        <h5 className="m-0" style={{ cursor: 'pointer' }}>{project?.name}</h5>
                      </Link>
                      <div className="cursor-pointer">
                        
                      </div>
                    </div>
                    <div className="card-body">
                      <div className='meta-info'>
                        <div className="row">
                          <div className="col-md-2">
                            <label>Sectors</label>
                              <h6>{project?.sector}</h6>
                          </div>
                          <div className="col-md-2">
                            <label>Sub Sectors</label>
                            <h6>{project?.sub_sector}</h6>
                          </div>
                          <div className="col-md-2">
                              <label>Location</label>
                              <h6>{project?.location}</h6>
                          </div>
                          <div className="col-md-2">
                              <label>Project Status</label>
                              <h6>{project.is_approved === 0 ? 'Inactive' : 'Active'}</h6>
                          </div>
                          
                          <div className="col-md-2">
                              <label>Applications</label>
                              <h6>{project?.applications_count || 0}</h6>
                          </div>

                          <div className="col-md-2">
                              <label>Posted on</label>
                              <h6>{formatDistanceToNow(new Date(project?.created_at))} ago</h6>
                          </div>

                        </div>
                      </div>
                      
                      <div className='action-buttons col-md-6 ms-auto'>
                      <Link to={`/edit-project/${project?.slug}`} className='btn btn-primary ml-2'>
                          <i className="material-icons" style={{ cursor: 'pointer' }} title="Edit">edit</i>
                        </Link>
                        {project.is_approved === 0 ? (
                          <button type="button" className="btn btn-success ms-3" title='Publish' onClick={() => handleApprove(project.id, 'publish')}><i className="material-icons ml-2">publish</i></button>
                        ) : (
                          <button type="button" className="btn btn-secondary ms-3" title='Draft' onClick={() => handleApprove(project.id, 'draft')}><i className="material-icons ml-2">unpublished</i></button>
                        )}
                        <Link to={`/project/${project?.slug}/matched-professionals`} title='View Matched Professionals' className="btn btn-dark mx-3"><i className="material-icons ml-2">diversity_1</i></Link>
                        <Link to={`/project/${project?.slug}/applicants`} title="View Applicants" className="btn btn-info"><i className="material-icons ml-2">groups</i></Link>
                        <Link to={`/project/${project?.slug}`} title="View Details" className="btn btn-primary mx-3"><i className="material-icons ml-2">visibility</i></Link>
                      </div>


                    </div>
                  </div>
                </div>

              ))}
            </div>

          </div>
        
      </Grid>


      {/* <div className="col-md-12">
              <div className="card">
                {showAlert && error && (
                  <>
                    <MDAlert color="danger" dismissible onClose={() => setShowAlert(false)}>
                      {error}
                    </MDAlert>
                  </>
                )}
                {showAlert && (
                  <>
                    <MDAlert color="success" dismissible onClose={() => setShowAlert(false)}>
                      {alertMessage}
                    </MDAlert>
                  </>
                )}
                <div className="card-body">
                  <div className="d-flex justify-content-end mb-3 mt-4 mx-3">
                    <MDButton variant="gradient" color="dark" onClick={() => navigate('/create-project')}>
                      Create Project
                    </MDButton>
                  </div>
                  <DataTable
                    table={{
                      columns: [
                        { Header: "id", accessor: "id", width: "5%" },
                        { Header: "name", accessor: "name", width: "20%" },
                        { Header: "type", accessor: "type", width: "20%" },
                        { Header: "location", accessor: "location", width: "20%" },
                        { Header: "applicants", accessor: "applicants", width: "5%" },
                        { Header: "Status", accessor: "status", width: "12%" },
                        { Header: "Action", accessor: "action", width: "20%" },
                      ],
                      rows: data.map((project) => ({
                        id: project.id || '',
                        name: project?.name || '',
                        type: project?.type || '',
                        location: project?.location || '',
                        applicants: project?.applications.length || 0,
                        status: project.is_approved === 0 ? 'Inactive' : 'Active',
                        action:
                          <>
                            <MDButton variant="gradient" color="info" onClick={() => navigate(`/project/${project?.id}/applicants`)} title="View Applicants">
                              <Icon>visibility</Icon>&nbsp;
                            </MDButton>
                            <MDButton variant="gradient" className="mx-2" color="info" onClick={() => navigate(`/project/${project?.id}/experts`)} title="View Matched Professionals">
                              <Icon>group</Icon>&nbsp;
                            </MDButton>

                            {project.is_approved === 0 ? (
                              <MDButton variant="gradient" color="info" onClick={() => handleApprove(project.id)} className="mx-2">
                                <Icon>check</Icon>&nbsp;
                              </MDButton>
                            ) : (
                              ''
                            )}

                            <MDButton variant="gradient" color="error" onClick={() => handleDelete(project.id)} className={project.is_approved === 1 ? "mx-2" : ""}>
                              <Icon>delete</Icon>&nbsp;
                            </MDButton>
                          </>
                      })),
                    }}
                  />
                </div>
              </div>
            </div> */}
      {/* </div>
        </div> */}


    </BasicLayout>
  )
}

export default Index