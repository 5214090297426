import React, { useEffect, useState } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Icon from "@mui/material/Icon";
import MDButton from 'components/MDButton';
import MDAlert from "components/MDAlert";
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../store/slices/authSlice';
import MDBox from "components/MDBox";
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const Index = () => {
    const [data, setData] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const { token, user } = useSelector(selectAuth);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const [open, setOpen] = useState(false);
    const [permissionName, setPermissionName] = useState('');
    const [error, setError] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState('');
    const [deletePermissionId, setDeletePermissionId] = useState(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    const navigate = useNavigate();
    if (user) {
        console.log(user?.user?.permissions)
    }

    const fetchData = async () => {
        try {
            if (token) {
                const response = await fetch(`${apiUrl}api/permissions`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const result = await response.json();
                setData(result.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const createPermission = async () => {
        try {
            const response = await fetch(`${apiUrl}api/permissions`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: permissionName }),
            });
            const result = await response.json();
            if (response.ok) {
                setSuccessMessage('Permission created successfully.');
                setSuccess(true);
                setShowAlert(true)
                setOpen(false)
                setPermissionName('');
                fetchData(); // Refresh data after creating permission
            } else {
                if (result.message) {
                    setError(result.message);
                }
                else {
                    setError(result.errors.name[0] || 'Something went wrong.');
                }
            }
        } catch (error) {
            console.error('Error creating permission:', error);
            setError('Something went wrong.');
        } finally {
            setTimeout(() => {
                setSuccess(false); // Reset success state after showing the success message
            }, 5000); // Reset success state after 5 seconds
        }
    };

    const editPermission = async () => {
        console.log("editId", editId)
        try {
            const response = await fetch(`${apiUrl}api/permissions/${editId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: permissionName }),
            });
            const result = await response.json();
            if (response.ok) {
                setSuccessMessage('Permission updated successfully.');
                setSuccess(true);
                setShowAlert(true)
                setOpen(false)
                setIsEdit(false)
                setPermissionName('');
                fetchData(); // Refresh data after creating permission
            } else {
                if (result.message) {
                    setError(result.message);
                }
                else {
                    setError(result.errors.name[0] || 'Something went wrong.');
                }
            }
        } catch (error) {
            console.error('Error creating permission:', error);
            setError('Something went wrong.');
        } finally {
            setTimeout(() => {
                setSuccess(false); // Reset success state after showing the success message
            }, 5000); // Reset success state after 5 seconds
        }
    };

    const handleDeletePermission = async () => {
        try {
            const response = await fetch(`${apiUrl}api/permissions/${deletePermissionId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                setSuccessMessage('Permission deleted successfully.');
                setSuccess(true);
                setShowAlert(true);
                fetchData(); // Refresh data after deleting permission
            } else {
                const result = await response.json();
                setError(result.message || 'Something went wrong.');
            }
        } catch (error) {
            console.error('Error deleting permission:', error);
            setError('Something went wrong.');
        } finally {
            // Close the delete confirmation dialog
            setConfirmDeleteOpen(false);
            setTimeout(() => {
                setSuccess(false); // Reset success state after showing the success message
            }, 5000); // Reset success state after 5 seconds
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="container-fluid py-4">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            {showAlert && success && (
                                <MDAlert color="success" dismissible onClose={() => setShowAlert(false)}>
                                    {successMessage}
                                </MDAlert>
                            )}
                            <Dialog
                                open={confirmDeleteOpen}
                                onClose={() => setConfirmDeleteOpen(false)}
                            >
                                <DialogTitle>Delete Permission</DialogTitle>
                                <DialogContent>
                                    <Typography variant="body1">Are you sure you want to delete this permission?</Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
                                    <Button onClick={handleDeletePermission}>Delete</Button>
                                </DialogActions>
                            </Dialog>
                            <div className="card-body">
                                <div className="d-flex justify-content-end mb-3 mt-4 mx-3">
                                    {user && user.user && user.user.permissions && user.user.permissions.includes('create permissions') && (

                                        <MDButton variant="gradient" color="dark" onClick={() => {
                                            setPermissionName('')
                                            setError(null)
                                            setOpen(true)
                                        }}>
                                            Create Permission
                                        </MDButton>
                                    )}
                                    <BootstrapDialog
                                        onClose={() => setOpen(false)}
                                        aria-labelledby="customized-dialog-title"
                                        open={open}
                                    >
                                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                                            Create Permission
                                        </DialogTitle>
                                        <IconButton
                                            aria-label="close"
                                            onClick={() => {
                                                setOpen(false)
                                                setPermissionName('')
                                                setError(null)
                                            }}
                                            sx={{
                                                position: 'absolute',
                                                right: 8,
                                                top: 8,
                                                color: (theme) => theme.palette.grey[500],
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <DialogContent dividers>
                                            <MDBox>
                                                <MDInput
                                                    type="text"
                                                    label="Name"
                                                    name="name"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    value={permissionName}
                                                    onChange={(e) => setPermissionName(e.target.value)}
                                                />
                                                <MDTypography variant="caption" color="error">
                                                    {error}
                                                </MDTypography>
                                            </MDBox>
                                        </DialogContent>

                                        <DialogActions>
                                            <Button autoFocus onClick={isEdit ? editPermission : createPermission}>
                                                Save changes
                                            </Button>
                                        </DialogActions>
                                    </BootstrapDialog>
                                </div>

                                <DataTable
                                    table={{
                                        columns: [
                                            { Header: "ID", accessor: "id", width: "10%" },
                                            { Header: "name", accessor: "name", width: "50%" },
                                            { Header: "Actions", accessor: "actions", width: "40%" },
                                        ],
                                        rows: data.map((permission) => {
                                            const editPermissionExists = user?.user?.permissions?.includes('edit permissions');
                                            const deletePermissionExists = user?.user?.permissions?.includes('delete permissions');

                                            let actions = null;

                                            if (editPermissionExists || deletePermissionExists) {
                                                actions = (
                                                    <MDBox display="flex" justifyContent="space-between">
                                                        {editPermissionExists && (
                                                            <MDButton variant="gradient" color="success" onClick={() => {
                                                                setPermissionName(permission.name);
                                                                setOpen(true);
                                                                setIsEdit(true);
                                                                setEditId(permission.id);
                                                            }}>
                                                                <Icon>edit</Icon>
                                                            </MDButton>
                                                        )}
                                                        {deletePermissionExists && (
                                                            <MDButton variant="gradient" color="error" sx={{ marginLeft: '5px' }} onClick={() => {
                                                                setDeletePermissionId(permission.id);
                                                                setConfirmDeleteOpen(true);
                                                            }}>
                                                                <Icon>delete</Icon>
                                                            </MDButton>
                                                        )}
                                                    </MDBox>
                                                );
                                            }

                                            return {
                                                id: permission.id || '',
                                                name: permission.name || '',
                                                actions: actions,
                                            };
                                        }),
                                    }}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </DashboardLayout>
    );
};

export default Index;
