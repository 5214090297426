import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../store/slices/authSlice';
import Footer from "examples/Footer";
import { MultiSelect } from "react-multi-select-component";
import UserInfo from "./userInfo";
import Password from "./password";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import BasicLayout from "layouts/authentication/components/BasicLayout";
const organizationTypes = [
    { label: "Construction", value: "Construction" },
    { label: "Development Consultant", value: "Development Consultant" },
    { label: "Funding Agency", value: "Funding Agency" },
    { label: "Finance", value: "Finance" },
    { label: "Foundation", value: "Foundation" },
    { label: "Government Organization", value: "Government Organization" },
    { label: "Institute", value: "Institute" },
    { label: "Manufacturer", value: "Manufacturer" },
    { label: "Service Provider", value: "Service Provider" },
    { label: "Goods Supplier", value: "Goods Supplier" },
    { label: "Works", value: "Works" },
    { label: "Other", value: "Other" }

];
const currencyCodes = [
    "USD", "EUR", "JPY", "GBP", "AUD", "CAD", "CHF", "CNY", "SEK", "NZD", "MXN", "SGD", "HKD", "NOK", "KRW", "TRY", "INR", "RUB", "BRL", "ZAR", "PHP", "PLN", "THB", "MYR", "IDR", "HUF", "CZK", "ILS", "CLP", "PKR", "BDT", "NPR"
];

const Index = () => {
    const [data, setData] = useState({
        company_name: '',
        website_link: '',
        organization_type: '',
        operational_countries: [],
        location: '',
        year_of_incorporation: '',
        incorporation_date: '',
        turnover: '',
        about: '',
        user: []
    });
    const [logo, setLogo] = useState(null);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const { token, user } = useSelector(selectAuth);
    const today = new Date().toISOString().split('T')[0];
    const fileInputRef = useRef(null); // Ref for file input
    const [error, setError] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('INR');

    const handleSelectCurrency = (code) => {
        setSelectedCurrency(code);
    };
    const fetchData = async () => {
        try {
            const response = await fetch(`${apiUrl}api/my-profile`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            console.log(result)
            if (result && result?.company) {
                setData({
                    company_name: result?.company.company_name,
                    organization_type: result?.company.organization_type,
                    website_link: result?.company.website_link,
                    operational_countries: [],
                    location: result.company.location,
                    year_of_incorporation: result.company.year_of_incorporation,
                    incorporation_date: result.company.incorporation_date,
                    turnover: result.company.turnover,
                    about: result.company.about,
                    user: result.user
                });
                setSelectedCurrency(result?.company.currency)
                setLogo(result?.company.logo);
            }

            if (result?.company?.operational_countries) {
                const parsedOperationalCountries = JSON.parse(result.company.operational_countries);
                const initialSelected = parsedOperationalCountries.map(country => ({
                    label: country,
                    value: country
                }));
                setSelected(initialSelected);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchCountries = async () => {
        try {
            const response = await fetch(`${apiUrl}api/countries`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (Array.isArray(result)) {
                setOptions(result.map(country => ({ label: country.name, value: country.name })));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'organization_type') {
            setData(data => ({
                ...data,
                organization_type: value
            }));
        } else {
            setData(data => ({
                ...data,
                [name]: value
            }));
        }
    };


    // const handleCompanyInfoSubmit = async (e) => {
    //     e.preventDefault();
    //     console.log("lll",e.target.organization_type.value);
    //     try {
    //         const response = await axios.put(`${apiUrl}api/employers/profile`, {
    //             company_name: e.target.company_name.value,
    //             organization_type: e.target.organization_type.value,
    //             operational_countries: selected.map(country => country.value),
    //             year_of_incorporation: e.target.year_of_incorporation.value,
    //             incorporation_date: e.target.incorporation_date.value,
    //             turnover: e.target.turnover.value,
    //             about: e.target.about.value,
    //         }, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //                 'Content-Type': 'multipart/form-data',
    //             }
    //         });
    //         console.log(response.data); // Handle success response
    //         toast.success("Company Info saved successfully !!", {
    //             className: "toast-message",
    //         });
    //         fetchData();
    //     } catch (error) {
    //         console.log(error)
    //         console.error('Error updating profile:', error); // Handle error
    //     }
    // };
    const handleCompanyInfoSubmit = async (e) => {
        e.preventDefault();
        try {
            const newData = {
                company_name: e.target.company_name.value,
                website_link: e.target.website_link.value,
                organization_type: e.target.organization_type.value,
                operational_countries: selected.map(country => country.value),
                year_of_incorporation: e.target.year_of_incorporation.value,
                incorporation_date: e.target.incorporation_date.value,
                turnover: e.target.turnover.value,
                currency: selectedCurrency,
                about: e.target.about.value,
            };
            const response = await axios.put(`${apiUrl}api/employers/profile`, newData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            console.log(response.data);
            toast.success("Company Info saved successfully !!", {
                className: "toast-message",
            });
            fetchData();
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };


    const handleEditClick = () => {
        fileInputRef.current.click(); // Trigger file input click event
    };
    // const handleLogoChange = async (e) => {
    //     const selectedFile = e.target.files[0];

    //     const { name, type } = e.target;
    // if (type === 'file') {
    //   setFormData({ ...formData, [name]: e.target.files[0] });
    // } 

    //     console.log("handleLogoChange")
    //     if (selectedFile) {
    //         console.log("Selected file:", selectedFile);


    //         try {
    //             const response = await axios.put(`${apiUrl}api/employers/company-logo`, formData , {
    //                 headers: {
    //                     'Authorization': `Bearer ${token}`,
    //                     'Content-Type': 'multipart/form-data',
    //                 }
    //             });
    //             console.log(response.data);
    // toast.success("Company Logo updated successfully !!", {
    //     className: "toast-message",
    // });
    //             fetchData();
    //         } catch (error) {
    //             console.error('Error updating logo:', error);
    //         }
    //     }
    // };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('logo', file);

        try {
            const response = await axios.post(`${apiUrl}api/employers/company-logo`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response)

            if (response.data.message) {
                fetchData();

                setError('');
                setLogo(response.data.logo);
                toast.success(response.data.message, {
                    className: "toast-message",
                });
            } else {
                setError(response.data.error);

            }
        } catch (error) {
            setError('Failed to upload logo. Please try again.');
        }
    };


    useEffect(() => {
        fetchData();
        fetchCountries()
    }, [token]);

    return (
        <BasicLayout>
            <ToastContainer />
<div className="inner-banner">
    <div className="container">
        <h2>{data?.company_name}</h2>
    </div>
    
</div>
                <div className="container employer-profile mt-5">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="card user-profile-left shadow">
                                <div className="profile-image-holder" style={{ backgroundColor: data?.logo ? '#fff' : '#fff', color: data?.logo ? '#fff' : '#000' }}>
                                    {logo ? (
                                        <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${logo}`} alt="Company Logo" />
                                    ) : (
                                        <span className="text-uppercase"><strong>{data?.company_name?.charAt(0)}</strong></span>
                                    )}
                                    <label title="Edit" onClick={handleEditClick}>
                                        <span class="material-symbols-outlined"> add_photo_alternate </span>
                                    </label>
                                    <input ref={fileInputRef} type="file" name="logo" accept="image/*" style={{ display: 'none' }} onChange={handleFileUpload} />
                                </div>
                                <div className='profile-meta'>
                                    <h4>{data?.company_name}</h4>
                                    {error && <p className="text-danger">{error}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 user-information">
                            <div className="card mb-4">
                                <div className="card-header">
                                    <h5>Company Info</h5>
                                </div>
                                <div className="card-body">
                                    <form onSubmit={handleCompanyInfoSubmit}>
                                        <div className='row mt-3'>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="company_name">Organization Name <span className='text-danger'>*</span></label>
                                                <input type="text" className="form-control mt-2" id="company_name" name="company_name" value={data?.company_name} required onChange={handleInputChange} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="organization_type">Organization Type <span className='text-danger'>*</span></label>
                                                <select
                                                    className="form-control mt-2"
                                                    id="organization_type"
                                                    required
                                                    name="organization_type"
                                                    value={data?.organization_type}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="">Select Type </option>
                                                    {organizationTypes?.map((type, index) => (
                                                        <option key={index} value={type.value}>{type.label}</option>
                                                    ))}
                                                </select>

                                            </div>
                                            <div className="form-group col-md-6 mt-3">
                                                <label htmlFor="website_link">Website <span className='text-danger'>*</span></label>
                                                <input type="url" className="form-control mt-2" id="website_link" name="website_link" value={data?.website_link} required onChange={handleInputChange} />
                                            </div>


                                            <div className="form-group col-md-6 mt-3">
                                                <label htmlFor="year_of_incorporation">Year of Incorporation</label>
                                                <input type="text" className="form-control mt-2" id="year_of_incorporation" name="year_of_incorporation" value={data?.year_of_incorporation} onChange={handleInputChange} />
                                            </div>
                                            <div className="form-group col-md-6 mt-3">
                                                <label htmlFor="incorporation_date">Incorporation Date</label>
                                                <input type="date" className="form-control mt-2" id="incorporation_date" name="incorporation_date" value={data?.incorporation_date} max={today} onChange={handleInputChange} />
                                            </div>
                                            <div className="form-group col-md-6 mt-3">
                                                <label htmlFor="turnover">Turnover (in Million)</label>

                                                <div class="input-group mt-2">
                                                    <input type="number" className="form-control" id="turnover" name="turnover" value={data?.turnover} min={0} onChange={handleInputChange} />
                                                    <div className="input-group-append">
                                                        <button
                                                            className="btn btn-currency dropdown-toggle"
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        >
                                                            {selectedCurrency || 'Select Currency'}
                                                        </button>
                                                        <div className="dropdown-menu" style={{ height: '200px', overflowY: 'auto', cursor: 'pointer' }}>
                                                            {currencyCodes.map((code, index) => (
                                                                <a
                                                                    className="dropdown-item"
                                                                    key={index}
                                                                    onClick={() => handleSelectCurrency(code)}
                                                                >
                                                                    {code}
                                                                </a>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6 mt-3">
                                                <label htmlFor="type">Operational Countires <span className='text-danger'>*</span></label>
                                                <MultiSelect
                                                    options={options}
                                                    value={selected}
                                                    onChange={setSelected}
                                                    labelledBy="Select"
                                                    name="operational_countries"
                                                    className='mt-2'
                                                    required
                                                />
                                            </div>
                                            <div className="form-group mb-4 mt-3">
                                                <label htmlFor="floatingTextarea2">About <span className='text-danger'>*</span></label>
                                                <textarea
                                                    className="form-control col-md-12 mt-2"
                                                    rows="5"
                                                    name="about"
                                                    value={data?.about}
                                                    onChange={handleInputChange}
                                                    required
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="text-center mt-5 mb-4">
                                            <button type="submit" className="btn btn-primary">Save Changes</button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                            <UserInfo data={data} user={user} token={token} />
                            <Password token={token} />
                        </div>
                    </div>
                </div>
            
        </BasicLayout>
    )
}

export default Index;
