import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import axios from 'axios';
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { Tooltip, IconButton, CircularProgress } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
function EmployerRegistration({ handleRegistrationType }) {
    const [employerFormData, setEmployerFormData] = useState({
        name: '',
        email: '',
        password: '',
        role_id: 3,
        company_name: '',
        phone: '',
        registration_certificate: null
    });
    const [employerSuccessMessage, setEmployerSuccessMessage] = useState(null);
    const [employererror, setEmployerError] = useState(null);
    const [employerVerify, setEmployerVerify] = useState(false);
    const [employerOtpSend, setEmployerOtpSend] = useState(false);
    const [otp, setOtp] = useState('')
    const [timer, setTimer] = useState(180); // 3 minutes in seconds
    const [showTimer, setShowTimer] = useState(false);
    const [proceedClicked, setProceedClicked] = useState(false);
    const [loading, setLoading] = useState(false);
    const { slug } = useParams();
    const [isChecked, setIsChecked] = useState(false);


    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const navigate = useNavigate();

    const handleEmployerChange = (e) => {
        const { name, type } = e.target;
        if (type === 'file') {
            setEmployerFormData({ ...employerFormData, [name]: e.target.files[0] });
        } else {
            const { value } = e.target;
            setEmployerFormData({ ...employerFormData, [name]: value });
        }
    };


    const handleOtp = (event) => {
        setOtp(event.target.value);
    };

    const handleEmployeerSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}api/employer-register`, employerFormData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Ensure correct content type
                }
            }); if (response.status === 200) {
                setLoading(false);
                navigate('/thank-you');
            }
            setEmployerSuccessMessage(response.data.message);
            setEmployerError(null);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                setEmployerError(error.response.data.error);
            } else if (error.request) {
                setEmployerError('No response received from the server');
            } else {
                setEmployerError('An error occurred while making the request');
            }
        }
    };

    const sendOtp = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${apiUrl}api/send-otp`, { email: employerFormData['email'] });
            if (response.status === 200) {
                setLoading(false);
                setEmployerSuccessMessage('OTP has been sent to your email');
                setEmployerOtpSend(true);
                setShowTimer(true);
            }
        } catch (error) {
            if (error.response) {
                setLoading(false);
                if (error.response.status === 422) {
                    console.log(error.response.data.errors)
                    setEmployerError(error.response.data.errors);
                } else {
                    setEmployerError(error.response.data.error);
                }
            } else if (error.request) {
                setLoading(false);
                setEmployerError('No response received from the server');
            } else {
                setLoading(false);
                setEmployerError('An error occurred while making the request');
            }
        }
    };


    const handleVerify = async () => {
        try {
            const response = await axios.post(`${apiUrl}api/verify-otp`, {
                email: employerFormData['email'],
                otp: otp
            });
            if (response.status === 200) {
                setEmployerVerify(true);
                setShowTimer(false);
            }
        } catch (error) {
            if (error.response) {
                setEmployerError(error.response.data.message);
            } else if (error.request) {
                setEmployerError('No response received from the server');
            } else {
                setEmployerError('An error occurred while making the request');
            }
        }
    };

    const validatePassword = () => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        if (!passwordRegex.test(employerFormData.password)) {
            setEmployerError({ password: ['Password format is invalid'] });
            return false;
        }
        if (!employerFormData.name.trim()) {
            setEmployerError({ name: ['Name is required'] });
            return false;
        }
        if (!employerFormData.phone.trim()) {
            setEmployerError({ phone: ['Phone Number is required'] });
            return false;
        }
        return true;
    };



    const handleProceed = () => {
        console.log(validatePassword, validatePassword())
        if (!validatePassword()) {
            setProceedClicked(false)

        }
        else {
            setProceedClicked(true)
            setEmployerError('')

        }

    }
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
      };

    useEffect(() => {
        let countdown;
        if (showTimer && timer > 0) {
            countdown = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        }
        return () => clearInterval(countdown);
    }, [showTimer, timer]);

    useEffect(() => {
        if (timer === 0) {
            setEmployerOtpSend(false);
            setShowTimer(false);
            setTimer(180); // Reset timer for next use
        }
        if (slug) {
            console.log(slug)
        }
    }, [timer, slug]);

    return (
        <Card className="mt-3">
            {loading && (
                <div className="spinner-overlay">
                    <CircularProgress />
                </div>
            )}
            <div className='sign-up-box-header'>
                <p>Register as an</p>
                <div className="buttonn-group">
                    <button className={`btn btn-outline-primary mx-2 active`} onClick={() => handleRegistrationType('employer')} >
                        Employer
                    </button>
                    <button className={`btn btn-outline-secondary mx-2`} onClick={() => handleRegistrationType('jobSeeker')} >
                        Professional
                    </button>
                </div>
            </div>

            <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                    {!proceedClicked ?
                        <>
                            <MDBox mb={2}>
                                <MDInput
                                    type="text"
                                    label="Name"
                                    name="name"
                                    variant="outlined"
                                    fullWidth
                                    value={employerFormData.name}
                                    onChange={handleEmployerChange}
                                    employererror={employererror && employererror.name}
                                    helperText={employererror && employererror.name && (
                                        <MDTypography variant="caption" color="error">
                                            {employererror.name[0]}
                                        </MDTypography>
                                    )}
                                    required
                                />
                            </MDBox>
                            <MDBox display="flex" alignItems="center" mb={2}>
                                <MDInput
                                    type="email"
                                    label="Email"
                                    name="email"
                                    variant="outlined"
                                    fullWidth
                                    value={employerFormData.email}
                                    onChange={handleEmployerChange}
                                    employererror={employererror && employererror.email}
                                    helperText={employererror && employererror.email && (
                                        <MDTypography variant="caption" color="error">
                                            {employererror.email[0]}
                                        </MDTypography>
                                    )}
                                    required
                                    disabled={employerVerify}
                                />
                                {!showTimer && !employerVerify ? (
                                    <MDButton variant="gradient" color="dark" onClick={sendOtp} ml={2}>
                                        Verify
                                    </MDButton>
                                ) : ''}
                            </MDBox>
                            {showTimer && employerOtpSend && (
                                <MDBox display="flex" alignItems="center" mb={2}>
                                    <MDInput
                                        type="text"
                                        label="OTP"
                                        name="otp"
                                        variant="outlined"
                                        fullWidth
                                        onChange={handleOtp}
                                        helperText={employererror ? (
                                            <MDTypography variant="caption" color="error">
                                                {employererror}
                                            </MDTypography>
                                        ) :
                                            <MDTypography variant="caption" color="success">
                                                {employerSuccessMessage}
                                            </MDTypography>
                                        }
                                        required
                                    />
                                    <MDButton variant="gradient" color="dark" onClick={handleVerify} ml={2}>
                                        Confirm
                                    </MDButton>
                                </MDBox>
                            )}
                            {showTimer && (
                                <div>
                                    <p>Time remaining: {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}</p>
                                </div>
                            )}

                            <MDBox mb={2}>
                                <MDInput
                                    type="text"
                                    label="Phone Number"
                                    name="phone"
                                    variant="outlined"
                                    fullWidth
                                    value={employerFormData.phone}
                                    onChange={handleEmployerChange}
                                    employererror={employererror && employererror.phone}
                                    helperText={employererror && employererror.phone && (
                                        <MDTypography variant="caption" color="error">
                                            {employererror.phone[0]}
                                        </MDTypography>
                                    )}
                                    required
                                />
                            </MDBox>
                            <MDBox mb={2} sx={{ position: 'relative' }}>
                                <MDInput
                                    type="password"
                                    label="Password"
                                    name="password"
                                    variant="outlined"
                                    fullWidth
                                    value={employerFormData.password}
                                    onChange={handleEmployerChange}
                                    employererror={employererror && employererror.password}
                                    helperText={employererror && employererror.password && (
                                        <MDTypography variant="caption" color="error">
                                            {employererror.password[0]}
                                        </MDTypography>
                                    )}
                                    required
                                />
                                <Tooltip
                                    title={
                                        <React.Fragment>
                                            <div>The password must meet the following criteria:</div>
                                            <ul>
                                                <li>The password contains at least one lowercase letter (a-z)</li>
                                                <li>The password contains at least one uppercase letter (A-Z)</li>
                                                <li>The password contains at least one digit (0-9)</li>
                                                <li>The password contains at least one special character</li>
                                                <li>The password is at least 8 characters long</li>
                                            </ul>
                                        </React.Fragment>
                                    }
                                    arrow
                                    placement="right"
                                >
                                    <IconButton sx={{ position: 'absolute', top: '50%', right: 8, transform: 'translateY(-50%)' }}>
                                        <InfoIcon sx={{ fontSize: 16, color: 'dark' }} />
                                    </IconButton>
                                </Tooltip>
                            </MDBox>
                        </>

                        :
                        <>
                            <MDBox mb={2}>
                                <MDInput
                                    type="text"
                                    label="Company Name"
                                    name="company_name"
                                    variant="outlined"
                                    fullWidth
                                    value={employerFormData.company_name}
                                    onChange={handleEmployerChange}
                                    employererror={employererror && employererror.company_name}
                                    helperText={employererror && employererror.company_name && (
                                        <MDTypography variant="caption" color="error">
                                            {employererror.company_name[0]}
                                        </MDTypography>
                                    )}
                                    required
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <div className="">
                                    <label for="formFile" className="form-label">Registration Certificate <span className='text-danger'>*</span></label>
                                    <input className="form-control" type="file" id="formFile"
                                        accept="image/*"
                                        name="registration_certificate"
                                        onChange={handleEmployerChange}
                                        required
                                    />
                                    {employererror && employererror.registration_certificate && (
                                        <MDTypography variant="caption" color="error">
                                            {employererror.registration_certificate[0]}
                                        </MDTypography>
                                    )}
                                </div>

                            </MDBox>
                        </>
                    }
                    {/* <MDBox display="flex" alignItems="center" ml={-1}>
                        <Checkbox />
                        <MDTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                        >
                            &nbsp;&nbsp;I agree the&nbsp;
                        </MDTypography>
                        <MDTypography
                            component="a"
                            href="#"
                            variant="button"
                            fontWeight="bold"
                            color="info"
                            textGradient
                        >
                            Terms and Conditions
                        </MDTypography>
                    </MDBox> */}
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="privacyPolicyCheckbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            required
                        />
                        <label className="form-check-label" htmlFor="privacyPolicyCheckbox">
                            <small className='text-muted'>I agree to the <a href="/privacy-policy" target="_blank">Privacy Policy</a> and <a href="/terms-and-conditions" target="_blank">Terms and Conditions</a>
                            </small></label>
                    </div>
                    <MDBox mt={4} mb={1}>
                        {proceedClicked ? (
                            <MDBox display="flex" justifyContent="space-between">
                                <MDButton variant="gradient" color="dark" onClick={() => setProceedClicked(false)}>
                                    Back
                                </MDButton>
                                <MDButton variant="gradient" color="info" onClick={handleEmployeerSubmit}>
                                    Register
                                </MDButton>
                            </MDBox>
                        ) : (
                            <MDButton
                                variant="gradient"
                                color="info"
                                fullWidth
                                onClick={handleProceed}
                                disabled={!employerVerify}
                            >
                                Proceed
                            </MDButton>
                        )}
                    </MDBox>

                    <MDBox mt={3} mb={1} textAlign="center">
                        <MDTypography variant="button" color="text">
                            Already have an account?{" "}
                            <MDTypography
                                component={Link}
                                to="/sign-in"
                                variant="button"
                                color="info"
                                fontWeight="medium"
                                textGradient
                            >
                                Sign In
                            </MDTypography>
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </MDBox>
        </Card>
    );
}

export default EmployerRegistration;
