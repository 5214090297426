
import React, { useEffect, useState, useRef } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { selectAuth } from '../../../../store/slices/authSlice';
import Footer from "examples/Footer";
import { MultiSelect } from "react-multi-select-component";
import UserInfo from "./userInfo";
import Password from "./password";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import BasicLayout from "layouts/authentication/components/BasicLayout";
import EditProfileModal from './EditProfileModal';
import { CircularProgress } from '@mui/material';
import EditAboutModal from './EditAboutModal';
import EditExperience from './EditExperience';
import { logout } from '../../../../store/slices/authSlice';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import EditEducation from './EditEducation';
import EditCertification from './EditCertification';
import EditSkill from './EditSkill';

const Index = () => {
    const [data, setData] = useState({
        designation: '',
        current_location: '',
        profile_picture: '',
        sectors: [],
        about: '',
        user: []
    });
    const [picture, setPicture] = useState(null);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [error, setError] = useState('');
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showAboutModal, setShowAboutModal] = useState(false);
    const [selected, setSelected] = useState([]);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const { token, user } = useSelector(selectAuth);
    const today = new Date().toISOString().split('T')[0];
    const fileInputRef = useRef(null); // Ref for file input
    const cookies = new Cookies();
    const authdispatch = useDispatch();
    const navigate = useNavigate()

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiUrl}api/my-profile`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            setData({
                profile_picture: result?.details?.profile_picture,
                details: result?.details,
                user: result.user,
                current_location: result?.details?.current_location,
            });
            console.log("here", data?.details)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData(data => ({
            ...data,
            [name]: value
        }));
    };

    const handleProfileUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`${apiUrl}api/employers/profile`, {
                company_name: e.target.company_name.value,
                operational_countries: selected.map(country => country.value),
                year_of_incorporation: e.target.year_of_incorporation.value,
                incorporation_date: e.target.incorporation_date.value,
                turnover: e.target.turnover.value,
                about: e.target.about.value,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            toast.success("Company Info saved successfully !!", {
                className: "toast-message",
            });
            fetchData();
        } catch (error) {
            console.log(error)
            console.error('Error updating profile:', error); // Handle error
        }
    };

    const handleLoader = () => {
        setLoading(true); // Show loader when button is clicked
        // Perform your asynchronous task here, e.g., API call, setTimeout, etc.
        setTimeout(() => {
            setLoading(false); // Hide loader when task is complete
            // Add your code to execute after the task is complete
        }, 2000); // Example: Simulating a 2-second delay
    };
    const handleEditClick = () => {
        fileInputRef.current.click(); // Trigger file input click event
    };
    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('profile_picture', file);

        try {
            const response = await axios.post(`${apiUrl}api/professional/update-profile-picture`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response)

            if (response.data.message) {
                fetchData();

                setError('');
                setPicture(response?.data?.profile_picture);
                toast.success(response.data.message, {
                    className: "toast-message",
                });
            } else {
                setError(response.data.error);

            }
        } catch (error) {
            setError('Failed to upload profile picture. Please try again.');
        }
    };

    const deleteAccount = async (e) => {
        if (window.confirm('Are you sure you want to delete your account?.')) {
            try {
                const response = await fetch(`${apiUrl}api/professional/delete-account/${user?.user?.id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    navigate('/account-deleted')
                    authdispatch(logout());
                    cookies.remove('token', { path: '/' });
                } else {
                    console.error('Failed to delete account');
                }
            } catch (error) {
                console.error('Network error:', error);
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, [token]);

    return (
        <BasicLayout>
            <ToastContainer
            />
            <EditProfileModal
                show={showProfileModal}
                handleClose={() => setShowProfileModal(false)}
                data={data}
                token={token}
                handleProfileUpdate={handleProfileUpdate}
                handleDataFetch={fetchData}
            />
            <EditAboutModal
                show={showAboutModal}
                handleClose={() => setShowAboutModal(false)}
                data={data}
                token={token}
                handleDataFetch={fetchData}
            />
            <div className='inner-banner'>
                <div className='container'><h2>Profile</h2></div>
            </div>
            <div className="container profile-page mt-5">
                <div className={showProfileModal || showAboutModal ? 'blurred-background' : ''}>
                    <div className='row'>
                        <div className='col-md-3 mb-md-5'>
                            <div className='card user-profile-left shadow'>
                            <div className="profile-image-holder" style={{backgroundColor: data?.profile_picture ? '#fff' : '#fff', color: data?.profile_picture ? '#fff' : '#000' }}>
                            {data?.profile_picture ? (
                            <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${data?.profile_picture}`} alt="Profile picture" />
                            ) : (
                            <span className="text-uppercase position-absolute top-50 start-50 translate-middle"><strong>{data?.user?.name?.charAt(0)}</strong></span>
                            )}
                            <label htmlFor="fileInput">
                            {data?.user?.id == user?.user?.id && (
                                <span class="material-symbols-outlined"> add_photo_alternate </span> 
                            )}
                            </label>
                            <input id="fileInput" ref={fileInputRef} type="file" name="profile_picture" accept="image/*" style={{ display: 'none' }} onChange={handleFileUpload} />
                            </div>
                            <hr></hr>
                            <div className='profile-meta'>

                            <h4>{data?.user?.name}</h4>
                            <h6><span class="material-symbols-outlined"> business_center </span>&nbsp; {data?.details?.designation}</h6>
                            {data?.details?.sectors && (
                            <>
                            <label>Sector</label>
                            <p>{data?.details?.sectors.join(" || ")}</p>
                            </>

                            )}
                            <label>Location</label>
                            <p><span class="material-symbols-outlined"> location_on </span>&nbsp; {data?.details?.current_location}</p>
                            <label>Infracon Id</label>
                            <p><span class="material-symbols-outlined"> apps </span>&nbsp; {data?.details?.infracon_id}</p>
                            <hr></hr>
                            <div className='altr-profile-data'>

                            <p className="edit-btn" onClick={() => setShowProfileModal(true)}>
                            {data?.user?.id == user?.user?.id && (
                                <>
                                    <span class="material-symbols-outlined"> edit_note </span> <span> Edit Profile</span>
                                </>
                            )}
                            </p>
                            <p className='delete-btn' onClick={deleteAccount}><span class="material-symbols-outlined"> delete </span> Delete Account</p>
                            </div>


                            </div>
                            </div>
                       
                        </div>
                        <div className='col-md-9 user-information'>
                        
                            <div className="row">
                                <div className="col-md-12">
                                    {data?.user?.id == user?.user?.id
                                        && (
                                            <>
                                            <div className="card mb-4">
                                            <div className="card-header d-flex justify-content-between align-items-center">
                                                <h5 className="me-auto">About</h5>
                                                <span className="ms-auto" onClick={() => setShowAboutModal(true)}>
                                                    <span class="material-symbols-outlined"> edit </span>
                                                </span>
                                            </div>

                                            <div className="card-body">
                                                {data?.details?.about ? (
                                                    <><p>{data.details.about}</p></>
                                                ) : (
                                                    <p className="text-muted">Your About section is your chance to shine! Tell potential employers what makes you stand out, your passions, skills, and ambitions. A compelling About section can be the key to unlocking exciting career opportunities. Don't miss this chance to showcase your unique story and talents.</p>
                                                )}
                                            </div>
                                        </div>
                                        <EditExperience data={data} user={user} token={token} handleDataFetch={fetchData} />
                                        <EditEducation data={data} user={user} token={token} handleDataFetch={fetchData} />
                                        <EditCertification data={data} user={user} token={token} handleDataFetch={fetchData} />
                                        <EditSkill data={data} user={user} token={token} handleDataFetch={fetchData} />
                                        </>
                                        )}
                                </div>
                            </div>
                        
                        </div>
                    </div>

                    
                </div>
            </div>
        </BasicLayout>
    )
}

export default Index;
