import React, { useEffect, useState } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Icon from "@mui/material/Icon";
import MDButton from 'components/MDButton';
import MDAlert from "components/MDAlert";
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';

const Index = () => {
    const [data, setData] = useState([]);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const { token, user } = useSelector(selectAuth);

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiUrl}api/professionals`);
            const result = await response.json();
            if (result) {
                setData(result);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleStatusUpdate = async (userId, status) => {
        setShowAlert(false);
        const isConfirmed = window.confirm(`Are you sure you want to ${status == 'active' ? 'activate' : 'deactivate'} this user?`);
        if (isConfirmed) {
            try {
                const response = await fetch(`${apiUrl}api/update-status/${userId}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    fetchData();
                    setShowAlert(true);
                    const result = await response.json();
                    setAlertMessage(result?.message);
                } else {
                    console.error('Failed to update status');
                }
            } catch (error) {
                console.error('Network error:', error);
            }
        }
    };

    const handleDelete = async (userId, userName) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete the user ${userName}?`);
        if (isConfirmed) {
            try {
                const response = await fetch(`${apiUrl}api/professional/delete/${userId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    fetchData();
                    setShowAlert(true);
                    const result = await response.json();
                    setAlertMessage(result?.message);
                } else {
                    console.error('Failed to delete user');
                }
            } catch (error) {
                console.error('Network error:', error);
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [token]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="container-fluid py-4">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            {showAlert && (
                                <>
                                    <MDAlert color="success" dismissible onClose={() => setShowAlert(false)}>
                                        {alertMessage}
                                    </MDAlert>
                                </>
                            )}
                            <div className="card-body">
                                <h3 className="d-flex mx-4 mt-2">
                                    <strong>Professionals</strong>
                                </h3>
                                <DataTable
                                    table={{
                                        columns: [
                                            { Header: "id", accessor: "id", width: "5%" },
                                            { Header: "name", accessor: "name", width: "20%" },
                                            { Header: "email", accessor: "email", width: "20%" },
                                            { Header: "Status", accessor: "status", width: "12%" },
                                            { Header: "Action", accessor: "action", width: "20%" },
                                        ],
                                        rows: data?.map((user) => ({
                                            id: user.id || '',
                                            name: user.name || '',
                                            email: user.email || '',
                                            status: user.status === 0 ? 'Inactive' : 'Active',
                                            action:
                                                <>
                                                    {user.status === 0 ? (
                                                        <MDButton variant="gradient" color="info" onClick={() => handleStatusUpdate(user.id, 'active')} className="mx-2">
                                                            <Icon>check</Icon>&nbsp;
                                                        </MDButton>
                                                    ) : (
                                                        <MDButton variant="gradient" color="info" onClick={() => handleStatusUpdate(user.id, 'inactive')} className="mx-2">
                                                            <Icon>clear</Icon>&nbsp;
                                                        </MDButton>
                                                    )}
                                                    <MDButton variant="gradient" color="error" onClick={() => handleDelete(user.id, user.name)}>
                                                        <Icon>delete</Icon>&nbsp;
                                                    </MDButton>
                                                </>
                                        })),
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </DashboardLayout>
    );
};

export default Index;
