
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";


// @mui material components
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";

// images
import logoprox from "../../../../assets/images/logo-proxtal.png";

function Footer({ light }) {
  const { size } = typography;

  return (
    <>
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-12 about-section-footer">
            <div className="brand-name">
            <img src={logoprox} alt="" />
            </div>
            <p>Proxtal revolutionizes international development by connecting professionals and firms, streamlining hiring, and matching skills with opportunities.</p>
            
          </div>
          <div className="col-md-3 col-6 quicklinks">
            <h2>Company</h2>
            <ul>
              <li>
                <Link href="/about-us" target="_blank"> About Us </Link>
              </li>
              <li>
                <Link href="/career" target="_blank"> Career </Link>
              </li>
              <li>
                <Link href="/faq" target="_blank"> FAQ </Link>
              </li>
              <li>
                <Link href="/support" target="_blank"> Support</Link>
              </li>
            </ul>

          </div>
          <div className="col-md-3 col-6 quicklinks">
          <h2>Quick Access</h2>
          <ul>
              <li>
                <Link href="/privacy-policy" target="_blank"> Privacy Policy</Link>
              </li>
              <li>
                <Link href="/terms-and-conditions" target="_blank"> Terms and Conditions</Link>
              </li>
              <li>
                <Link href="/site-map" target="_blank"> Sitemap</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-12 quicklinks social-links">
            <span><Link href="https://www.linkedin.com/company/proxtal" target="_blank"> <i class="fa-brands fa-linkedin"></i></Link></span>
            <span><Link href="https://x.com/proxtal_" target="_blank"> <i class="fa-brands fa-x-twitter"></i></Link></span>
            <span><Link href="https://www.facebook.com/proxtal" target="_blank"> <i class="fa-brands fa-square-facebook"></i></Link></span>
            <span><Link href="https://www.instagram.com/proxtal_" target="_blank"> <i class="fa-brands fa-square-instagram"></i></Link></span>
            
          </div>
        </div>
      </div>
    </div>
    <div className="footer-copyright">
      <p>&copy; {new Date().getFullYear()}, made with <i class="fa-solid fa-heart"></i> by Team Proxtal</p>
    </div>

    </>
  );

}

// Setting default props for the Footer
Footer.defaultProps = {
  light: false,
};

// Typechecking props for the Footer
Footer.propTypes = {
  light: PropTypes.bool,
};

export default Footer;
