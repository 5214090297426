import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import axios from 'axios';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';

const currencyCodes = [
    "USD", "EUR", "JPY", "GBP", "AUD", "CAD", "CHF", "CNY", "SEK", "NZD", "MXN", "SGD", "HKD", "NOK", "KRW", "TRY", "INR", "RUB", "BRL", "ZAR", "PHP", "PLN", "THB", "MYR", "IDR", "HUF", "CZK", "ILS", "CLP", "PKR", "BDT", "NPR"
];
const Edit = () => {
    const [error, setError] = useState(null);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const { token, user } = useSelector(selectAuth);
    const [showAlert, setShowAlert] = useState(false);
    const [sectors, setSectors] = useState([]);
    const [selectedSector, setSelectedSector] = useState(null);
    const [selectedSubsector, setSelectedSubsector] = useState(null);
    const [subsectors, setSubsectors] = useState([]);
    const [showTender, setShowTender] = useState(false);
    const { slug } = useParams();
    const [selectedCurrency, setSelectedCurrency] = useState('INR');

    const today = new Date().toISOString().split('T')[0];
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        id: '',
        is_approved: '',
        title: '',
        category: '',
        sector: '',
        sub_sector: '',
        stage: '',
        experience: '',
        deadline: '',
        location: '',
        language: '',
        salary: '',
        job_type: '',
        personnel_type: '',
        description: '',
        currency: ''
    });

    const handleSelectCurrency = (code) => {
        setSelectedCurrency(code);
        setFormData({ ...formData, currency: code });
    };

    const handleInputChange = (e) => {
        const { name, type, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const fetchData = async () => {
        if (slug && token) {
            try {
                const headers = {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };

                const url = `${apiUrl}api/edit-job/${slug}`;
                const response = await axios.get(url, { headers });

                if (response) {
                    const job = response?.data?.job;
                    if (job) {
                        setFormData({
                            id: job.id || '',
                            is_approved: job.is_approved || '',
                            title: job.title || '',
                            category: job.category || '',
                            stage: job.stage || '',
                            experience: job.experience || '',
                            deadline: job.deadline || '',
                            location: job.location || '',
                            language: job.language || '',
                            salary: job.salary || '',
                            job_type: job.job_type || '',
                            personnel_type: job.personnel_type || '',
                            description: job.description || '',
                            currency: job?.currency || 'INR'
                        });

                        if (job?.sector) {
                            fetchSectorsData(job.sector, job.sub_sector);
                        }
                       setSelectedCurrency(job.currency || 'INR')
                    }
                }
            } catch (error) {
                console.error('Error fetching job data:', error);
                setError('Error fetching job data. Please try again.');
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}api/edit-job-details/${slug}`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            navigate('/posted-jobs');
        } catch (error) {
            console.error('Error creating project:', error);
            setError('Error creating project. Please try again.');
        }
    };

    const fetchSectorsData = async (sectorName, subSectorName) => {
        try {
            const response = await fetch(`${apiUrl}api/get-sectors`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();

            setSectors(result);

            // Find the sector and update the state
            const sector = result.find(s => s.name === sectorName);

            if (sector) {
                setSelectedSector(sector.id);
                setFormData(prevFormData => ({
                    ...prevFormData,
                    sector: sector.id,
                    sub_sector: subSectorName,
                }));
                setSubsectors(sector.children);
                setSelectedSubsector(subSectorName);
            }

        } catch (error) {
            console.error('Error fetching sector data:', error);
        }
    };

    const handleSectorChange = (event) => {
        const sectorId = event.target.value;
        setSelectedSector(sectorId);
        setFormData({ ...formData, sector: sectorId });

        const sector = sectors.find(s => s.id === parseInt(sectorId));
        if (sector) {
            setSubsectors(sector.children);
        }
    };

    const handleSubsectorChange = (event) => {
        const subsectorId = event.target.value;
        setSelectedSubsector(subsectorId);
        setFormData({ ...formData, sub_sector: subsectorId });
    };

    const handleStageChange = (event) => {
        const stageValue = event.target.value;
        setShowTender(stageValue === 'Proposal');
        setFormData({ ...formData, stage: stageValue });
    };
    const handleApprove = async (jobId, message) => {
        // Show a confirmation dialog
        setShowAlert(false)
        const isConfirmed = window.confirm(message === 'publish' ? 'Are you sure you want to publish this job?' : 'Are you sure you want to draft this job?');

        if (isConfirmed) {
            try {
                const response = await fetch(`${apiUrl}api/job/${jobId}/update-status`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
                const result = await response.json();

                if (response.ok) {
                    fetchData();
                    setShowAlert(true);
                    console.log("result", result)
                    toast.success(result?.message);


                } else {
                    // Handle error, e.g., show an error message
                    console.error('Failed to approve job');
                }
            } catch (error) {
                // Handle network error
                console.error('Network error:', error);
            }
        }
    };

    const handleDelete = async (jobId) => {
        const confirmed = window.confirm('Are you sure you want to delete this job?');
        if (!confirmed) return; // If user cancels, do nothing
        try {
            const response = await fetch(`${apiUrl}api/job/${jobId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (response.ok) {
                navigate('/posted-jobs');
                toast.success('Job details deleted successfully!');
                fetchData();
            } else {
                setShowAlert(true);
                setError(result.message || 'Failed to delete job');
            }
        } catch (error) {
            console.error('Error deleting Job:', error);
            setShowAlert(true);
            setError('Failed to delete Job. Please try again later.');
        }
    }

    useEffect(() => {
        fetchSectorsData();
        fetchData();
    }, [slug, token]);

    return (
        <BasicLayout>
            <ToastContainer />
            <div className="container mt-5 py-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className='row mt-3 mb-2 p-3'>
                                <div className='d-flex justify-content-between align-items-center w-100'>
                                    <h3 className='text-start'>Edit Job details</h3>
                                    <div>
                                        <button className='btn btn-dark me-2' onClick={() => navigate(-1)}>
                                            Back
                                        </button>
                                        {formData?.is_approved == 0 ? (
                                            <button type="button" className="btn btn-success me-2" onClick={() => handleApprove(formData?.id, 'publish')}>Publish</button>
                                        ) : (
                                            <button type="button" className="btn btn-secondary me-2" onClick={() => handleApprove(formData?.id, 'draft')}>Draft</button>
                                        )}
                                        <button className='btn btn-danger' onClick={() => handleDelete(formData?.id)}>Delete</button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="d-flex justify-content-end mb-3">
                                </div>
                                {formData?.title ? (
                                    <form onSubmit={handleSubmit}>
                                        <div className='row mt-3'>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="title">Job Title <span className='text-danger'>*</span></label>
                                                <input type="text" className="form-control mt-2" id="title" name="title" value={formData?.title} onChange={handleInputChange} required />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="location">Location <span className='text-danger'>*</span></label>
                                                <input type="text" className="form-control mt-2" id="location" name="location" value={formData?.location} onChange={handleInputChange} required />
                                            </div>

                                            <div className="form-group col-md-6 mt-4">
                                                <label htmlFor="language">Languages <span className='text-danger'>*</span></label>
                                                <input type="text" className="form-control mt-2" id="language" name="language" value={formData?.language} onChange={handleInputChange} required />
                                            </div>

                                            <div className="form-group col-md-6  mt-4">
                                                <label htmlFor="stage">Job Stage <span className='text-danger'>*</span></label>
                                                <select className="form-control mt-2" id="stage" name="stage" value={formData?.stage} onChange={handleStageChange} required>
                                                    <option value="">Select Job Stage</option>
                                                    <option value="Current">Current</option>
                                                    <option value="Proposal">Proposal</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6  mt-4">
                                                <label htmlFor="type">Sector <span className='text-danger'>*</span></label>
                                                <select className="form-control mt-2" id="type" name="type" value={formData?.sector} onChange={handleSectorChange} required>
                                                    <option value="">Select Sector</option>
                                                    {sectors.map(sector => (
                                                        <option key={sector.id} value={sector.id}>{sector.name}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="form-group col-md-6  mt-4">
                                                <label htmlFor="type">Sub Sector <span className='text-danger'>*</span></label>
                                                <select className="form-control mt-2" id="type" name="type" value={formData?.sub_sector} onChange={handleSubsectorChange} required>
                                                    <option value="">Select Sub Sector</option>
                                                    {subsectors.map(subsector => (
                                                        <option key={subsector.id} value={subsector.name}>{subsector.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6 mt-4">
                                                <label htmlFor="category">Category <span className='text-danger'>*</span></label>
                                                <select className="form-control mt-2" id="category" name="category" value={formData?.category} onChange={handleInputChange} required>
                                                    <option value="">Select Category</option>
                                                    <option value='Goods'>Goods</option>
                                                    <option value='Works'>Works</option>
                                                    <option value='Consulting Services'>Consulting Services</option>
                                                    <option value='Other Non-Consulting Services'>Other Non-Consulting Services</option>
                                                </select>
                                            </div>

                                            <div className="form-group col-md-6  mt-4">
                                                <label htmlFor="job_type">Job Type <span className='text-danger'>*</span></label>
                                                <select className="form-control mt-2" id="job_type" name="job_type" value={formData?.job_type} onChange={handleInputChange} required>
                                                    <option value="">Select Job Type</option>
                                                    <option value="Full-time">Full-time</option>
                                                    <option value="Part-time">Part-time</option>
                                                    <option value="Contract">Contract</option>
                                                    <option value="Permanent">Permanent</option>
                                                    <option value="Temporary">Temporary</option>
                                                    <option value="Seasonal">Seasonal</option>
                                                    <option value="Intermittent">Intermittent</option>
                                                    <option value="Internship">Internship</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6  mt-4">
                                                <label htmlFor="experience">Experience</label>
                                                <input type="text" className="form-control mt-2" id="experience" name="experience" value={formData?.experience} onChange={handleInputChange} />
                                            </div>

                                            <div className="form-group col-md-6  mt-4">
                                                <label htmlFor="personnel_type">Personnel Type <span className='text-danger'>*</span></label>
                                                <select className="form-control mt-2" id="personnel_type" name="personnel_type" value={formData?.personnel_type} onChange={handleInputChange} required>
                                                    <option value="">Select Personnel Type</option>
                                                    <option value="National">National</option>
                                                    <option value="International">International</option>
                                                </select>
                                            </div>

                                            <div className="form-group col-md-6  mt-4">
                                                <label htmlFor="salary">Salary <span className='text-danger'>*</span></label>
                                                <div class="input-group mt-2">
                                                    <input type="text" className="form-control" id="salary" name="salary" value={formData?.salary} onChange={handleInputChange} required />
                                                    <div className="input-group-append">
                                                        <button
                                                            className="btn btn-outline-secondary dropdown-toggle"
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        >
                                                            {selectedCurrency || 'Select Currency'}
                                                        </button>
                                                        <div className="dropdown-menu" style={{ height: '200px', overflowY: 'auto', cursor: 'pointer' }}>
                                                            {currencyCodes.map((code, index) => (
                                                                <a
                                                                    className="dropdown-item"
                                                                    key={index}
                                                                    onClick={() => handleSelectCurrency(code)}
                                                                >
                                                                    {code}
                                                                </a>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="form-group col-md-6  mt-4">
                                                <label htmlFor="deadline">Deadline <span className='text-danger'>*</span></label>
                                                <input type="date" className="form-control mt-2" id="deadline" name="deadline" min={today} value={formData?.deadline} onChange={handleInputChange} />
                                            </div>


                                            <div className="form-group col-md-12 mt-4">
                                                <label htmlFor="description">Job Description <span className='text-danger'>*</span></label>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data={formData.description}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setFormData({ ...formData, description: data });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="text-center mt-5 mb-4">
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </form>
                                ) : (
                                    <div className="spinner-overlay">
                                        <CircularProgress />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BasicLayout>
    );
};

export default Edit;
