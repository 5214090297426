import React, { useEffect, useState } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../store/slices/authSlice';
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import { useNavigate } from 'react-router-dom';

const Create = () => {
  const [selected, setSelected] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [roleName, setRoleName] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
  const { token, user } = useSelector(selectAuth);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate()

  const fetchData = async () => {
    try {
      if (token) {
        const response = await fetch(`${apiUrl}api/permissions`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        console.log(result.data);
        setPermissions(result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    setShowAlert(false)
    e.preventDefault();
    if (selected.length === 0) {
      setError('Permissions field is required.');
      return;
    }
    try {
      const response = await fetch(`${apiUrl}api/roles`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: roleName,
          permissions: selected.map(permission => permission.value)
        }),
      });
      const result = await response.json();
      if (response.ok) {
        setShowAlert(true);
        setSuccess(true);
        setError(null);
        setSelected([])
        setRoleName('')
        // Optionally, you can reset form fields here
      } else {
        setSuccess(false);
        setError(result.message || 'Failed to create role.');
      }
    } catch (error) {
      console.error('Error creating role:', error);
      setError('Failed to create role. Please try again later.');
      setSuccess(false);
    }
  };

  const options = permissions.map(permission => ({
    label: permission.name,
    value: permission.id.toString(),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              {showAlert && success && (
                <>
                  <MDAlert color="success" dismissible onClose={() => setShowAlert(false)}>
                    Role created successfully
                  </MDAlert>
                </>
              )}
              <div className="card-body">
                <div className="d-flex justify-content-end mb-3">
                  <MDButton variant="gradient" color="dark" onClick={() => navigate('/roles')}
                  >
                    Back
                  </MDButton>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className="form-group col-md-6">
                      <label for="name">Role Name <span className='text-danger'>*</span></label>
                      <input type="text" className="form-control mt-2" id="name" required value={roleName} onChange={(e) => setRoleName(e.target.value)} />
                    </div>
                    <div className='form-group col-md-6'>
                      <label for="name">Permissions <span className='text-danger'>*</span></label>
                      <MultiSelect
                        options={options}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Select"
                        className='mt-2'
                        required
                      />
                      <MDTypography variant="caption" color="error">
                        {error}
                      </MDTypography>
                    </div>
                  </div>

                  <div className="text-center mt-5 mb-4"> 
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </div>                
                  </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default Create;
