import React, { useEffect, useState } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { MultiSelect } from "react-multi-select-component";
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../store/slices/authSlice';
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from 'components/MDButton';
import { useNavigate, useParams } from 'react-router-dom';

const Edit = () => {
  const { id } = useParams(); // Get the role ID from the URL params
  const [selected, setSelected] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [roleName, setRoleName] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
  const { token, user } = useSelector(selectAuth);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  // Fetch role details based on ID
  const fetchRoleDetails = async () => {
    try {
      const response = await fetch(`${apiUrl}api/roles/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (response.ok) {
        setRoleName(result.name);
        setSelected(result.permissions.map(permission => ({ label: permission.name, value: permission.id.toString() })));
      } else {
        setError(result.message || 'Failed to fetch role details.');
      }
    } catch (error) {
      console.error('Error fetching role details:', error);
      setError('Failed to fetch role details. Please try again later.');
    }
  };

  // Fetch permissions
  const fetchPermissions = async () => {
    try {
      const response = await fetch(`${apiUrl}api/permissions`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      console.log(result.data);
      setPermissions(result.data);
    } catch (error) {
      console.error('Error fetching permissions:', error);
      setError('Failed to fetch permissions. Please try again later.');
    }
  };

  useEffect(() => {
    fetchRoleDetails();
    fetchPermissions();
  }, []); // Fetch data only once on component mount

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}api/roles/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: roleName,
          permissions: selected.map(permission => permission.value),
        }),
      });
      const result = await response.json();
      if (response.ok) {
        setShowAlert(true);
        setSuccess(true);
        setError(null);
      } else {
        setSuccess(false);
        setError(result.message || 'Failed to update role.');
      }
    } catch (error) {
      console.error('Error updating role:', error);
      setError('Failed to update role. Please try again later.');
      setSuccess(false);
    }
  };
  

  const options = permissions.map(permission => ({
    label: permission.name,
    value: permission.id.toString(),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              {showAlert && success && (
                <>
                  <MDAlert color="success" dismissible onClose={() => setShowAlert(false)}>
                    Role updated successfully
                  </MDAlert>
                </>
              )}
              <div className="card-body">
              <div className="d-flex justify-content-end mb-3">
                  <MDButton variant="gradient" color="dark" onClick={() => navigate('/roles')}
                  >
                    Back
                  </MDButton>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className="form-group col-md-6">
                      <label htmlFor="name">Role Name <span className='text-danger'>*</span></label>
                      <input type="text" className="form-control mt-2" id="name" aria-describedby="emailHelp" required value={roleName} onChange={(e) => setRoleName(e.target.value)} />
                    </div>
                    <div className='form-group col-md-6'>
                      <label htmlFor="name">Permissions <span className='text-danger'>*</span></label>
                      <MultiSelect
                        options={options}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Select"
                        className='mt-2'
                        required
                      />
                      <MDTypography variant="caption" color="error">
                        {error}
                      </MDTypography>
                    </div>
                  </div>

                  <div className="text-center mt-5 mb-4"> 
                    <button type="submit" className="btn btn-primary">Update</button>
                  </div>                
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Edit;
