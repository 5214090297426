import React, { useEffect, useState } from 'react';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import Icon from "@mui/material/Icon";
import MDButton from 'components/MDButton';
import MDAlert from "components/MDAlert";
import MDBadge from "components/MDBadge";
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../store/slices/authSlice';
import MDBox from "components/MDBox";
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const [data, setData] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { token, user } = useSelector(selectAuth);
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      if (token) {
        const response = await fetch(`${apiUrl}api/roles`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        setData(result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDelete = async (roleId) => {
    try {
      const response = await fetch(`${apiUrl}api/roles/${roleId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (response.ok) {
        setShowAlert(true);
        setSuccess(result.message || 'Role deleted successfully');
        // Refresh data after successful deletion
        fetchData();
      } else {
        setShowAlert(true);
        setError(result.message || 'Failed to delete role');
      }
    } catch (error) {
      console.error('Error deleting role:', error);
      setShowAlert(true);
      setError('Failed to delete role. Please try again later.');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              {showAlert && error &&(
                <MDAlert color='error' dismissible onClose={() => setShowAlert(false)}>
                  {error}
                </MDAlert>
              )}
              {showAlert && success && (
                <>
                  <MDAlert color="success" dismissible onClose={() => setShowAlert(false)}>
                  {success}
                  </MDAlert>
                </>
              )}
              <div className="card-body">
                <div className="d-flex justify-content-end mb-3 mt-4 mx-3">
                  <MDButton variant="gradient" color="dark" onClick={() => navigate('/create-role')}>
                    Create Role
                  </MDButton>
                </div>
                <DataTable
                  table={{
                    columns: [
                      { Header: "ID", accessor: "id", width: "5%" },
                      { Header: "Role", accessor: "name", width: "20%" },
                      { Header: "Permissions", accessor: "permissions", width: "30%" },
                      { Header: "Actions", accessor: "actions", width: "30%" },
                    ],
                    rows: data.map((role) => ({
                      id: role.id || '',
                      name: role.name || '',
                      permissions: (
                        <div>
                          {role.permissions.map(permission => (
                            <MDBadge key={permission} color="info" size='lg' 
                            badgeContent={permission}>
                            </MDBadge>
                          ))}
                        </div>
                      ),
                      actions: (
                        <MDBox display="flex" justifyContent="space-between">
                          <MDButton variant="gradient" color="success" onClick={() => navigate(`/edit-role/${role.id}`)}>
                            <Icon>edit</Icon>
                          </MDButton>
                          {!(role.id >= 1 && role.id <= 4) && 
                            <MDButton variant="gradient" color="error" sx={{ marginLeft: '5px' }} onClick={() => handleDelete(role.id)}>
                              <Icon>delete</Icon>
                            </MDButton>
                          }
                        </MDBox>
                      ),
                    })),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </DashboardLayout>
  );
};

export default Index;
