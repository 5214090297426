import React from 'react';
import BasicLayout from "layouts/authentication/components/BasicLayout";
import MDButton from 'components/MDButton';
import { Grid } from '@mui/material';

const TermsAndConditions = () => {
    return (
        <BasicLayout>
            <Grid container justifyContent="center" alignItems="center">
                <div className="container mt-5 p-5">
                    <h2 className="text-center mb-4 mt-5">Terms and Conditions</h2>
                    <div className="terms-content text-start">
                        <h5 className='mt-2'>1. Introduction</h5>
                        <p>Welcome to Proxtal! These Terms and Conditions govern your use of our platform. By accessing or using Proxtal, you agree to comply with these terms. Please read them carefully.</p>

                        <h5 className='mt-2'>2. Definitions</h5>
                        <p><strong>“Proxtal”:</strong> Refers to the platform operated by Deqtal Consulting India</p>
                        <p><strong>“User”:</strong> Any individual or legal entity accessing or using Proxtal.</p>
                        <p><strong>“Content”:</strong> Includes profiles, project information, messages, and any other material on Proxtal.</p>

                        <h5 className='mt-2'>3. Acceptance of Terms</h5>
                        <p>By using Proxtal, you agree to these Terms and Conditions. If you do not agree, please refrain from using the platform.</p>

                        <h5 className='mt-2'>4. User Conduct</h5>
                        <p>You agree not to:</p>
                        <ul>
                            <li>Violate any applicable laws or regulations.</li>
                            <li>Create false profiles or impersonate others.</li>
                            <li>Post harmful, offensive, or misleading content.</li>
                            <li>Use Proxtal for illegal purposes.</li>
                            <li>Infringe on intellectual property rights.</li>
                        </ul>

                        <h5 className='mt-2'>5. Account Registration</h5>
                        <p>You must provide accurate and complete information during registration. Keep your login credentials confidential. Notify us immediately of any unauthorized use of your account.</p>

                        <h5 className='mt-2'>6. Privacy and GDPR Compliance</h5>
                        <p>Our Privacy Policy outlines how we collect, use, and protect personal data. By using Proxtal, you consent to our privacy practices. We comply with the General Data Protection Regulation (GDPR) and other relevant privacy laws.</p>

                        <h5 className='mt-2'>7. Intellectual Property</h5>
                        <p>Proxtal’s content and features are protected by intellectual property laws. Users retain ownership of their content but grant Proxtal a license to use it for platform purposes.</p>

                        <h5>8. Termination</h5>
                        <p>We reserve the right to suspend or terminate accounts violating these terms. You may delete your account at any time.</p>

                        <h5 className='mt-2'>9. Limitation of Liability</h5>
                        <p>Proxtal is provided “as is.” We are not liable for any damages arising from its use. We do not endorse or guarantee the accuracy of user-generated content.</p>

                        <h5 className='mt-2'>10. Governing Law</h5>
                        <p>These terms are governed by the laws of India. Any disputes will be resolved in the courts of Gurugram, Haryana.</p>

                        <h5 className='mt-2'>11. Changes to Terms</h5>
                        <p>We may update these terms. Check this page periodically for changes.</p>

                    </div>
                </div>
            </Grid>
        </BasicLayout>
    )
}

export default TermsAndConditions;