import React, { useEffect, useState } from 'react';
import Footer from "examples/Footer";
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../store/slices/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDButton from 'components/MDButton';
import { Icon } from '@mui/material';
import DataTable from 'examples/Tables/DataTable';

const Index = () => {
  const [data, setData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { token, user } = useSelector(selectAuth);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      if (token) {
        const response = await fetch(`${apiUrl}api/admin-jobs`, {
          method: 'GET', // Adjust the HTTP method as needed
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        setData(result);
        console.log(result);
      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleApprove = async (projectId, message) => {
    // Show a confirmation dialog
    setShowAlert(false)
    const isConfirmed = window.confirm(message === 'publish' ? 'Are you sure you want to publish this job?' : 'Are you sure you want to draft this job?');

    if (isConfirmed) {
      try {
        const response = await fetch(`${apiUrl}api/job/${projectId}/update-status`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();

        if (response.ok) {
          fetchData();
          setShowAlert(true);
          console.log("result", result)
          toast.success(result?.message);


        } else {
          // Handle error, e.g., show an error message
          console.error('Failed to approve job');
        }
      } catch (error) {
        // Handle network error
        console.error('Network error:', error);
      }
    }
  };

  const handleDelete = async (jobId) => {
    const confirmed = window.confirm('Are you sure you want to delete this job?');
    if (!confirmed) return; // If user cancels, do nothing
    try {
      const response = await fetch(`${apiUrl}api/job/${jobId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (response.ok) {
        toast.success('Job details deleted successfully!');
        fetchData();
      } else {
        setShowAlert(true);
        setError(result.message || 'Failed to delete job');
      }
    } catch (error) {
      console.error('Error deleting Job:', error);
      setShowAlert(true);
      setError('Failed to delete Job. Please try again later.');
    }
  }
  useEffect(() => {
    fetchData();
  }, [token]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />
      <div className="container-fluid py-4">
                <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <h3 className="d-flex mx-4 mt-2">
              <strong>Jobs</strong>
            </h3>
            <DataTable
              table={{
                columns: [
                  { Header: "id", accessor: "id", width: "5%" },
                  { Header: "title", accessor: "title", width: "20%" },
                  { Header: "category", accessor: "category", width: "20%" },
                  { Header: "location", accessor: "location", width: "20%" },
                  { Header: "Status", accessor: "status", width: "12%" },
                  { Header: "Action", accessor: "action", width: "20%" },
                ],
                rows: data?.map((job) => ({
                  id: job?.id || '',
                  title: job?.title || '',
                  category: job?.category || '',
                  location: job?.location || '',
                  status: job?.is_approved === 0 ? 'Inactive' : 'Active',
                  action:
                  <>
                  <MDButton variant="gradient" color="info" onClick={() => navigate(`/job/${job?.slug}/view`)} title="View">
                    <Icon>visibility</Icon>&nbsp;
                  </MDButton>
                  <MDButton variant="gradient" color="secondary" className="mx-2" onClick={() => navigate(`/job/${job?.slug}/edit`)} title="Edit">
                    <Icon>edit</Icon>&nbsp;
                  </MDButton>

                  {job?.is_approved === 0 ? (
                    <MDButton variant="gradient" color="success" title='publish' onClick={() => handleApprove(job.id, 'publish')} className="mx-2">
                      <Icon>check</Icon>&nbsp;
                    </MDButton>
                  ) : (
                      <MDButton variant="gradient" color="warning" title='draft' onClick={() => handleApprove(job.id, 'draft')} className="mx-2">
                      <Icon>close</Icon>&nbsp;
                    </MDButton>
                  )}

                  <MDButton variant="gradient" color="error" onClick={() => handleDelete(job.id)}>
                    <Icon>delete</Icon>&nbsp;
                  </MDButton>
                </>
                })),
              }}
            />
          </div>
        </div>
      </div>
      </div>
      </div>


      <Footer />
    </DashboardLayout>
  );
};

export default Index;
