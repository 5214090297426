import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import MDInput from "components/MDInput";
import MDBox from 'components/MDBox';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Delete, Edit } from '@mui/icons-material';


const EditEducation = ({ data, user, token, handleDataFetch }) => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const [education, setEducation] = useState([]);
    const [showEducationModal, setShowEducationModal] = useState(false);
    const [selectedEducation, setSelectedEducation] = useState(''); // State to hold the selected education for editing
    const [error, setError] = useState(false);
    const [dateError, setDateError] = useState('');
    const [showFullDescription, setShowFullDescription] = useState(false);
    const currentDate = new Date();
    // Extract the current year and month
    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits for month

    // Format the end date
    const endDate = `${currentYear}-${currentMonth}`;
    // Format the max date
    const maxDate = `${currentYear}-${currentMonth}`;

    const initialState = {
        school: '',
        degree: '',
        study: '',
        grade: '',
        description: '',
        start_date: '2024-01',
        end_date: endDate,
    };

    const [formData, setFormData] = useState(initialState);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("selectedEducation", selectedEducation)

        // Construct new education object
        const newEducation = {
            school: formData.school,
            degree: formData.degree,
            study: formData.study,
            grade: formData.grade,
            description: formData.description,
            start_date: formData.start_date,
            end_date: formData.end_date,
            id: selectedEducation
        };

        try {
            const id = data?.user?.id;
            // Make the API call to update education details
            const response = await axios.post(`${apiUrl}api/professional/add-education/${id}`, { education: [newEducation] }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            toast.success("Education details saved successfully !!", {
                className: "toast-message",
            });
            // Close modal
            setShowEducationModal(false);
            handleDataFetch();

        } catch (error) {
            console.error('Error updating education details:', error);
        }
    }


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        const isPresentValue = name === 'currently_working' ? checked : formData.isPresent;

        setFormData({ ...formData, [name]: newValue, isPresent: isPresentValue });
        let errorMessage = '';
        if (name === 'start_date' || name === 'end_date') {
            const startDate = name === 'start_date' ? new Date(value) : new Date(formData.start_date);
            const endDate = name === 'end_date' ? new Date(value) : new Date(formData.end_date);
            const currentDate = new Date();

            if (startDate > currentDate) {
                setError(true);
                errorMessage = 'Start date cannot be in the future.';
                setDateError(errorMessage)
            }
             else if (startDate > endDate) {
                setError(true);
                errorMessage = 'Start date should not be after end date.';
                setDateError(errorMessage)
            }
            else {
                errorMessage = '';
                setError(false);
            }
            console.log("errorMessage", errorMessage);
        }

    }
    function convertDateFormat(dateString) {
        if (dateString) {
            const dateParts = dateString.split('-');
            const year = dateParts[0];
            const month = new Date(dateString + '-01').toLocaleString('en-us', { month: 'short' });
            return `${month} ${year}`;
        }

    }

    const toggleFeatures = () => {
        setShowFullDescription(!showFullDescription);
    };

    const openAddModal = () => {
        setFormData(initialState);
        setShowEducationModal(true);

    }

    const handleEdit = (index) => {
        setSelectedEducation(index);
        setFormData({ ...education[index] });
        setShowEducationModal(true);
    }

    const handleDelete = (index) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this education?");
        if (confirmDelete) {
            deleteEducation(index);
        }
    }

    const deleteEducation = async (EducationId) => {
        const id = data?.user?.id;
        try {
            const response = await axios.delete(`${apiUrl}api/professional/delete-education/${id}/${EducationId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            // Handle success response
            toast.success("Education details deleted successfully !!", {
                className: "toast-message",
            });

            // After successful deletion, you might want to update the UI or refetch data
            handleDataFetch();
        } catch (error) {
            console.error('Something went wrong:', error);
            // Handle error response
            toast.error("Something went wrong. Please try again later.", {
                className: "toast-message",
            });
        }
    }


    useEffect(() => {
        setEducation(data?.details?.education);
    }, [data]);

    return (
        <div className="card mb-4 mt-3">
            {/* Card Header */}
            <div className="card-header d-flex justify-content-between align-items-center">
                <h5>Education</h5>
                {data?.user?.id == user?.user?.id && (
                    <div className="cursor-pointer" onClick={openAddModal}>
                        <i className="material-icons" style={{ cursor: 'pointer' }} title="Add">add</i>
                    </div>
                )}
            </div>

            {/* Card Body */}
            {data?.details?.education && data?.details?.education.length > 0? (

                <div className="card-body">
                    <div className="accordion" id="accordionEducation">
                        {data?.details?.education.map((edu, index) => (
                            <div className="accordion-item" key={index}>
                                <h2 className="accordion-header" id={`edu-heading${index}`}>
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#edu-collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                        {edu.school} <span className='px-1'> ({convertDateFormat(edu?.start_date)}{edu.end_date ? ` - ${convertDateFormat(edu.end_date)}` : ''})</span>
                                    </button>
                                </h2>
                                <div id={`edu-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`edu-heading${index}`} data-bs-parent="#accordionEducation">
                                    <div className="accordion-body d-flex justify-content-between align-items-center">
                                        <div className='meta-info'>
                                            <h5>{edu.school}</h5>
                                            <h6 className='text-muted fs-8'>({convertDateFormat(edu?.start_date)}{edu.end_date ? ` - ${convertDateFormat(edu.end_date)}` : ''})  {edu?.employment_type && (` (${edu?.employment_type})`)}</h6>
                                            {edu?.degree && (
                                                <p className="pt-3"><strong>Degree: </strong><span>{edu?.degree}</span></p>
                                            )}
                                            {edu?.study && (
                                                <p className="pt-1"><strong>Field of study: </strong><span>{edu?.study}</span></p>
                                            )}
                                            {edu?.grade && (
                                                <p className="pt-1"><strong>Grade: </strong><span>{edu?.grade}</span></p>
                                            )}
                                            {edu?.description && (
                                                <p className='text-muted pt-3'>
                                                    {showFullDescription ?
                                                        <span dangerouslySetInnerHTML={{ __html: edu?.description }} /> :
                                                        <span dangerouslySetInnerHTML={{ __html: edu?.description.substring(0, 200) + "..." }} />
                                                    }
                                                        {edu?.description.length > 200 && (
                                                            <span className="text-secondary" style={{ cursor: 'pointer' }} onClick={toggleFeatures}>
                                                                {showFullDescription ? " View less" : " View more"}
                                                            </span>
                                                        )}
                                                </p>
                                            )}
                                        </div>
                                        <div className='buttons'>
                                            <span className="edit-btn" onClick={() => handleEdit(index)}>
                                                <span class="material-symbols-outlined"> edit </span>
                                            </span>
                                            <div className="delete-btn" onClick={() => handleDelete(index)}>
                                                <span class="material-symbols-outlined"> delete </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ))}
                    </div>
                </div>

            ) : (
                <div className="card-body">
                    <p className='text-muted'>
                        <small>Please add your education details to increase your chances of being contacted by job recruiters. Providing this information helps highlight your qualifications and expertise.</small>
                    </p>
                </div>
            )}

            {/* Modal */}
            <div className={`modal ${showEducationModal ? 'show' : ''}`} style={{ display: showEducationModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add education</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowEducationModal(false)}>
                                <span aria-hidden="true" className='p-2'>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body w-100 h-100">
                            <form onSubmit={handleSubmit}>
                                {/* Form Fields */}
                                <div className="form-group">
                                    <label htmlFor="school">School <span className='text-danger'>*</span></label>
                                    <input type="text" className="form-control" placeholder="Ex: Indian Institute of Technology, Bombay" id="school" name="school" required onChange={handleChange} value={formData.school} maxLength={150}/>
                                </div>
                                <div className="form-group mt-4">
                                    <label htmlFor="degree">Degree</label>
                                    <input type="text" className="form-control" placeholder="Ex: Bachelor of Arts - BA" id="degree" name="degree" onChange={handleChange} value={formData.degree} maxLength={150}/>
                                </div>

                                <div className="form-group mt-4">
                                    <label htmlFor="study">Field of study</label>
                                    <input type="text" className="form-control" placeholder="Ex: Design and Applied Arts" id="study" name="study" onChange={handleChange} value={formData.study} maxLength={150}/>
                                </div>


                                <div className="form-group mt-4">
                                    <div className='row mt-4 mb-4'>
                                        <div className='col-md-6'>
                                            <label htmlFor="start_date" className="form-label">Start Date </label>
                                            <MDInput type="month" id="start_date" name="start_date" onChange={handleChange} value={formData.start_date} />
                                        </div>
                                            <div className='col-md-6'>
                                                <label htmlFor="end_date" className="form-label">End Date (or expected)</label>
                                                <MDInput type="month" id="end_date" name="end_date" onChange={handleChange} value={formData.end_date} />
                                            </div>
                                    </div>
                                    {error && dateError && <small className="text-danger"><small>{dateError}</small></small>}
                                </div>

                                <div className="form-group mt-4">
                                    <label htmlFor="grade">Grade</label>
                                    <input type="text" className="form-control" placeholder="Ex: Design and Applied Arts" id="grade" name="grade" onChange={handleChange} value={formData.grade} maxLength={20}/>
                                </div>


                                <div className="form-group mt-4 mb-4">
                                    <label htmlFor="description">Description</label>
                                    <textarea className="form-control" style={{ height: '100px' }} name='description' onChange={handleChange} value={formData?.description}></textarea>
                                    <div className="text-sm-end text-muted"><small>0/500 characters</small></div>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setShowEducationModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">Save changes</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditEducation;
