
import { useState, useEffect } from "react";

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth } from '../../../store/slices/authSlice';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import { logout } from '../../../store/slices/authSlice';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

/* Import Mmenu */
import Mmenu from 'mmenu-js';
import 'mmenu-js/dist/mmenu.css';

// images
import logoprox from "../../../assets/images/logo-proxtal.png";

function DefaultNavbar({ transparent, light, action }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);
  const { token, user } = useSelector(selectAuth);
  const authdispatch = useDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate()

  const NavbarUserMenu = ({ user, light }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };
    const handleLogout = () => {
      // Implement your logout logic here
      authdispatch(logout());
      navigate('/')
      handleMenuClose();
      cookies.remove('token', { path: '/' });
    };
    const redirectTo = user?.user?.role_id === 4 ? '/profile' : '/my-profile';

    return (
      <>
        <li className="nav-item account-name">
          <a href="#" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown">
            <span className="profile-icon ">
              {user && user.user && user.user.image ? (
                <Avatar alt={user.user.name} src={user.user.image} />
              ) : (
                <span className="text-uppercase">{user?.user?.name?.charAt(0)}</span>
              )}
            </span>
            <span className="username">{user?.user?.name}</span>
          </a>
          <ul class="dropdown-menu">
            <li>
              <Link to={redirectTo} className="dropdown-item"> Profile </Link>
            </li>
            <hr className="my-0"></hr>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>

          </ul>

        </li>

      </>
    );


  };


  const NavbarProjectMenu = ({ light }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleItemClick = (item) => {
      setSelectedItem(item);
    };


    return (
      <>
        <li className="nav-item" onClick={handleMenuOpen} >
          <a href="#" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >Projects</a>
          <ul class="dropdown-menu">
            <li>
              <Link to='/all-projects' className="dropdown-item">
                All Projects
              </Link>
            </li>
            <li>
              <Link to='/my-projects' className="dropdown-item">
                My Projects
              </Link>
            </li>
            <li>
              <Link to='/create-project' className="dropdown-item">
                Add new project
              </Link>
            </li>

          </ul>
        </li>
        {/* <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <Link to='/all-projects'>
            <MenuItem>All Projects</MenuItem>
          </Link>
          <Link to='/my-projects'>
            <MenuItem>My Projects</MenuItem>
          </Link>
          <Link to='/create-project'>
            <MenuItem >Add new project</MenuItem>
          </Link>
        </Menu> */}
      </>
    );


  };

  const NavbarJobMenu = ({ light }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleItemClick = (item) => {
      setSelectedItem(item);
    };


    return (
      <>
        <li onClick={handleMenuOpen} >
          <a href="#" className="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" >Jobs</a>
          <ul class="dropdown-menu">
            <li>
              <Link to='/all-jobs' className="dropdown-item"> All Jobs </Link>
            </li>

            {user?.user?.role_id == 3 ?
              <>
                <li>
                  <Link to='/add-new-job' className="dropdown-item"> Add New Job </Link>
                </li>

                <li>
                  <Link to='/posted-jobs' className="dropdown-item"> Posted Jobs </Link>
                </li>
              </>
              :
              <>
                <li>
                  <Link to='/applied-jobs'> Applied Jobs </Link>
                </li>
              </>
            }
          </ul>
        </li>
      </>
    );


  };

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);



  return (
    <>

      <nav class="navbar navbar-expand-sm bg-light navbar-light fixed-top primary-nav">
        <div class="container">

          {!token ? (
            <MDBox
              component={Link}
              to="/"
              className="prox-logo navbar-brand"
            >
              <img src={logoprox} alt="Logo" />
            </MDBox>
          ) : (
            <img src={logoprox} alt="Logo" className="prox-logo navbar-brand" />
          )}

          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="collapsibleNavbar">
            <ul class="align-items-start align-items-md-center d-flex justify-content-evenly me-auto nav navbar-nav">
              {token ? (
                <>
                  {user?.user?.role_id != 4 && user?.user?.role_id != 3 && (
                    <li>
                      <DefaultNavbarLink name="dashboard" route="/dashboard" />
                    </li>
                  )}

                  {user?.user?.role_id == 3 ?
                    <NavbarProjectMenu light={light} />
                    :
                    <li className="nav-item"><DefaultNavbarLink className='nav-link' name="projects" route="/all-projects" light={light} /></li>
                  }
                  {token && user ?
                    <NavbarJobMenu light={light} />
                    :
                    <li className="nav-item"><DefaultNavbarLink className='nav-link' name="jobs" route="/all-jobs" /></li>
                  }
                  <li className="nav-item"><DefaultNavbarLink className='nav-link' name="professionals" route="/professionals" /> </li>
                  <li className="nav-item"> <DefaultNavbarLink className='nav-link' name="organizations" route="/organizations" light={light} /> </li>
                  {/* <li className="nav-item"> <DefaultNavbarLink className='nav-link' name="contact us" route="/contact-us" /> </li> */}

                </>
              )
                :
                <>
                  <DefaultNavbarLink name="projects" route="/all-projects" light={light} />
                  <DefaultNavbarLink name="jobs" route="/all-jobs" />
                  <DefaultNavbarLink name="professionals" route="/professionals" light={light} />
                  <DefaultNavbarLink name="organizations" route="/organizations" light={light} />


                </>

              }
            </ul>
            <ul class="nav navbar-nav ms-auto">
              <DefaultNavbarLink name="Support" route="/support" />
              {token ? (
                <>
                  <NavbarUserMenu user={user} light={light} />
                </>
              )
                :
                <>
                  <DefaultNavbarLink name="sign in" route="/sign-in" light={light} />
                </>
              }
            </ul>
          </div>
        </div>
      </nav>


      {/*         <MDBox
          display={{ xs: "inline-block", lg: "none" }}
          lineHeight={0}
          py={1.5}
          pl={1.5}
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={openMobileNavbar}
        >
          <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
        </MDBox>
      
      {mobileView && <DefaultNavbarMobile open={mobileNavbar} close={closeMobileNavbar} />} */}


    </>
  );
}


// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;
