import BasicLayout from 'layouts/authentication/components/BasicLayout';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../../store/slices/authSlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
// import ShowApplyModal from './ShowApplyModal';
import { formatDistanceToNow, format, parseISO } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import ShowShareModal from 'layouts/projects/ShowShareModal';
import ShowApplyModal from 'layouts/projects/ShowApplyModal';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';


const View = () => {
    const [data, setData] = useState([]);
    const [recomJob, setRecomJob] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showCopyModal, setShowCopyModal] = useState(false);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const [showFullFeatures, setShowFullFeatures] = useState(false);
    const { token, user, isNewUser } = useSelector(selectAuth);
    const [companyName, setSelectedCompanyName] = useState('');
    const [projectId, setSelectedProjectId] = useState('');
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { slug } = useParams();
    const [loading, setLoading] = useState(false);
    const { position } = useParams();
    const [positions, setPositions] = useState([]);


    function reverseSlug(positionData) {

        // Replace hyphens with spaces
        let text = positionData.replace(/-/g, ' ');

        // Capitalize each word (optional, depends on your requirement)
        text = text.split(' ').map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
        return text;
    }

    function createSlug(text) {
        // Replace all spaces with hyphens and convert to lowercase
        return text.trim().toLowerCase().replace(/\s+/g, '-');
    }

    const toggleFeatures = () => {
        setShowFullFeatures(!showFullFeatures);
    };
    const handleApply = (name, id, positionName) => {
        setSelectedCompanyName(name)
        setSelectedProjectId(id)
        setShowModal(true)
        setPositions(positionName)
    }

    const fetchData = async () => {
        try {
            const response = await fetch(`${apiUrl}api/jobs/${slug}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            console.log('result', result);
            setData(result?.job[0]);

            setRecomJob(result?.recommendedProjects);
            console.log(result?.recommendedProjects)



        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const copyURLToClipboard = () => {
        const currentURL = window.location.href;
        navigator.clipboard.writeText(currentURL);
        setShowCopyModal(false)
        toast.success('Link copied to clipboard!');
    };

    const convertDate = (date) => {
        const deadline = date;
        const formattedDeadline = deadline ? format(parseISO(deadline), 'MMMM d, yyyy') : 'N/A';
        return formattedDeadline;
    }

    useEffect(() => {
        fetchData();

    }, [token, slug]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ToastContainer />
            <ShowApplyModal
                show={showModal}
                type='job'
                handleClose={() => setShowModal(false)}
                user={user}
                token={token}
                companyName={companyName}
                projectId={projectId}
                positions={positions}
                handleDataFetch={fetchData}
            />
            <ShowShareModal
                show={showCopyModal}
                handleClose={() => setShowCopyModal(false)}
                copyURLToClipboard={copyURLToClipboard}
            />


            <div className="container w-100">
                <div className={showModal || showCopyModal ? 'blurred-background' : ''}>
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className="card">
                                <div className='card-header bg-secondary'>
                                    <div className="row align-items-center">
                                        <div className="col-md-2">
                                            <div className="rounded-circle d-inline-flex align-items-center justify-content-center" style={{ width: '100px', height: '100px', backgroundColor: data?.company?.logo ? '#000' : '#87CEEB', color: user?.details?.profile_picture ? '#87CEEB' : '#000' }}>

                                                {data?.company?.logo ? (
                                                    <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${data?.company?.logo}`} alt="Profile picture" className="img-fluid rounded-circle" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                ) : (
                                                    <span className="text-uppercase"><strong>{data?.company?.company_name?.charAt(0)}</strong></span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <p className='text-white'><strong>{data?.title}</strong></p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row p-3'>
                                    <div className='col-md-9'>
                                        {!token && (
                                            <p className='mb-0'>
                                                <small>
                                                    To access full details and apply for jobs associated with this project, please
                                                    <Link to="/sign-in"> sign in</Link> to your current account or
                                                    <Link to="/sign-up"> sign up</Link> for a free account.
                                                </small>
                                            </p>
                                        )}
                                    </div>

                                    <div className='col-md-3 d-flex justify-content-end align-items-center'>
                                        <button className='btn btn-info me-2' onClick={() => setShowCopyModal(true)}>Share</button>
                                        <button className='btn btn-dark' onClick={() => navigate(-1)}>Back</button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className='card shadow mb-5 bg-white rounded'>
                                        <div className='card-header'><strong>Job Details</strong></div>
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-md-8'>
                                                    <h6 className="pt-2"><strong>Posted Date : </strong><span className="text-muted">{convertDate(data?.created_at)}</span></h6>
                                                    <h6 className="pt-2"><strong>Organization: </strong><span className="text-muted">{data?.company?.company_name}</span></h6>

                                                    <h6 className="pt-2"><strong>Sector: </strong><span className="text-muted">{data?.sector}</span></h6>
                                                    <h6 className="pt-2"><strong>Sub Sector: </strong><span className="text-muted">{data?.sub_sector}</span></h6>
                                                    <h6 className="pt-2"><strong>Category: </strong><span className="text-muted">{data?.category}</span></h6>
                                                    <h6 className="pt-2"><strong>Stage: </strong><span className="text-muted">{data?.stage}</span></h6>
                                                    {data?.salary && (
                                                        <h6 className="pt-2"><strong>Salary: </strong>
                                                            {token ?
                                                                <span className="text-primary">{data?.salary}</span>
                                                                :
                                                                <i className="material-icons ml-2 fs-6" title="Posted on">lock</i>
                                                            }
                                                        </h6>
                                                    )}
                                                    <h6 className="pt-2"><strong>Language: </strong><span className="text-muted">{data?.language}</span></h6>
                                                    <h6 className="pt-2"><strong>Locations: </strong><span className="text-muted">{data?.location}</span></h6>
                                                    {data?.project_id && (
                                                        <h6 className="pt-2"><strong>Project: </strong>
                                                        <Link to={`/project/${data?.project?.slug}`}>
                                                             <span className="text-primary">{data?.project?.name}</span>
                                                        </Link>
                                                        </h6>
                                                    )}
                                                </div>

                                                <div className='col-md-4 justify-content-end'>
                                                    <div>
                                                        <i className="material-icons fs-6" title="Posted on">group</i>
                                                        <span className="fs-6 text-success"> {data?.applications?.length} applicants</span>
                                                    </div>
                                                    <h6 className="pt-2"><strong>Deadline: </strong><span className="text-danger">{convertDate(data?.deadline)}</span></h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='card mt-5 shadow mb-5 bg-white rounded'>
                                        <div className='card-header'><strong>Description</strong></div>
                                        <div className="card-body">
                                            {token ?
                                                <div className='fs-6' dangerouslySetInnerHTML={{ __html: data?.description }}></div>
                                                :
                                                <>
                                                    <i className="material-icons ml-2 fs-6" title="Posted on">lock</i>

                                                    <span className="text-secondary mx-2">
                                                        Please <Link to="/sign-in">sign in</Link> or{' '}
                                                        <Link to="/sign-up">create a free account</Link> to see this description.
                                                    </span>
                                                </>

                                            }
                                        </div>
                                    </div>

                                    {data?.company?.about && (
                                        <>
                                            <div className='card mt-5 shadow mb-5 bg-white rounded'>
                                                <div className='card-header'><strong>About Organization</strong></div>
                                                <div className="card-body">
                                                    <div className='fs-6'>{data?.company?.about}</div>
                                                </div>
                                            </div>
                                        </>

                                    )}
                                    {token ? (
                                        user && user?.user?.role_id === 4 ? (
                                            data?.applications?.some(application => application.user_id === user.user.id && application?.position == data?.title) ? (
                                                <>
                                                    <div className="col-md-12 d-flex justify-content-center mt-5 mb-3">
                                                        <span className="text-success ">Application already submitted</span>
                                                    </div>
                                                </>

                                            ) : (

                                                <div className="col-md-12 d-flex justify-content-center mt-5 mb-3">
                                                    {/* <Link to={`/job/${createSlug(job?.title)}/project/${job?.slug}`}>
                                                                        <a className="btn btn-dark rounded-pill w-20">View Details</a>
                                                                    </Link> */}
                                                    <button className="btn btn-primary d-flex align-items-center justify-content-center rounded-pill w-30 mx-3" onClick={() => handleApply(data?.company?.company_name, data?.id, data?.title)} >
                                                        Apply <i className="material-icons ml-2">arrow_forward</i>
                                                    </button>
                                                </div>
                                            )
                                        ) : null
                                    ) : (
                                        // <div className="col-md-12 d-flex justify-content-center mt-5 mb-3">
                                        //     <button className="btn btn-primary d-flex align-items-center justify-content-center rounded-pill w-30 mx-3" onClick={() => navigate('/sign-in')}>
                                        //         Login to Apply <i className="material-icons ml-2">arrow_forward</i>
                                        //     </button>
                                        // </div>
                                        <></>
                                    )}

                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <h3 className='text-success text-center'>Releated jobs for you</h3>
                            {recomJob.length > 0 ?
                                <>
                                    {recomJob.map((job, index) => (
                                        <>
                                            <div className="col-md-12 py-3" key={index}>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="row align-items-center">
                                                            <div className="col-md-2">
                                                                <div className="rounded-circle d-inline-flex align-items-center justify-content-center" style={{ width: '50px', height: '50px', backgroundColor: job?.company?.logo ? '#000' : '#87CEEB', color: user?.details?.profile_picture ? '#87CEEB' : '#000' }}>

                                                                    {job?.company?.logo ? (
                                                                        <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${job?.company?.logo}`} alt="Profile picture" className="img-fluid rounded-circle" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                                    ) : (
                                                                        <span className="text-uppercase"><strong>{job?.company?.company_name?.charAt(0)}</strong></span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <Link to={`/job/${position}/project/${job?.slug}`}>
                                                                    <h5 className="card-title text-primary"><strong>{position}</strong></h5>
                                                                </Link>
                                                                {token && (
                                                                    <h6>{job?.company?.company_name}</h6>
                                                                )}

                                                            </div>

                                                        </div>
                                                        <div className='my-3 d-flex justify-content-between align-items-center'>
                                                            {convertDate(job?.deadline) && (
                                                                <div>
                                                                    <i className="material-icons fs-6" title="Closing on">schedule</i>
                                                                    <span className="fs-6 text-muted"> Closing on  {convertDate(job?.deadline)}</span>
                                                                </div>
                                                            )}

                                                            <div className='px-3'>
                                                                <i className="material-icons fs-6" title={job?.stage === "Current" ? "Job openings currently available" : "This organization is bidding on a project and offering a job if the project is approved"}
                                                                >info</i>
                                                                <span className="fs-6 text-muted"> {job?.stage}</span>
                                                            </div>
                                                        </div>
                                                        <hr></hr>
                                                        <div className='mx-2'>
                                                            <div className='row mb-3'>
                                                                <div className="col-md-6 pt-2 ">
                                                                    <h6><strong>Sub Sector</strong></h6>
                                                                    <h6 className="text-muted">{job?.sub_sector}</h6>
                                                                </div>
                                                                <div className="col-md-6 pt-2 float-end">
                                                                    <h6><strong>Location</strong></h6>
                                                                    <h6 className="text-muted">{job?.location}</h6>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </>
                                    ))}
                                </>
                                :
                                <>
                                    <div className="col-md-12 mt-5">
                                        <div className="card-shadow">
                                            <p className='text-center'>
                                                <span class="material-symbols-outlined fs-1">
                                                    sentiment_dissatisfied
                                                </span>
                                            </p>
                                            <h5 className='text-center'>No Releated jobs found </h5>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>


                </div>

            </div>

        </DashboardLayout>
    );
};

export default View;
