import { Grid, Autocomplete, TextField, Icon, CircularProgress } from '@mui/material';
import BasicLayout from 'layouts/authentication/components/BasicLayout'
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import countryData from '../../assets/data/country-state.json';
import MDPagination from 'components/MDPagination';

const organizationTypes = [
    { label: "Construction", value: "Construction" },
    { label: "Development Consultant", value: "Development Consultant" },
    { label: "Funding Agency", value: "Funding Agency" },
    { label: "Finance", value: "Finance" },
    { label: "Foundation", value: "Foundation" },
    { label: "Government Organization", value: "Government Organization" },
    { label: "Institute", value: "Institute" },
    { label: "Manufacturer", value: "Manufacturer" },
    { label: "Service Provider", value: "Service Provider" },
    { label: "Goods Supplier", value: "Goods Supplier" },
    { label: "Works", value: "Works" },
    { label: "Other", value: "Other" }

];
const Index = () => {
    const [data, setData] = useState([]);
    const [userData, setUserData] = useState([]);
    const { token, user } = useSelector(selectAuth);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [stateCountryList, setStateCountryList] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);


    const handleTypeChange = (event) => {
        const value = event.target.value;
        setSelectedType(value);
    };


    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const fetchFilteredData = async () => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams();
            if (selectedLocations.length > 0) {
                queryParams.append('locations', selectedLocations.map(loc => loc.value));
            }
            if (selectedType) {
                queryParams.append('organization_type', selectedType);
            }
            if (searchTerm) {
                queryParams.append('search', searchTerm); // Added project name filter
            }
            queryParams.append('page', currentPage);
            queryParams.append('items_per_page', itemsPerPage);

            const response = await fetch(`${apiUrl}api/organizations?${queryParams.toString()}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (result) {
                console.log(result);
                setLoading(false);
                setData(result?.data?.data || []);
                setUserData(user);
                setCurrentPage(result.data.current_page);
                setTotalPages(result.data.last_page);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    const fetchCountries = async () => {
        try {
            const response = await fetch(`${apiUrl}api/countries`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (Array.isArray(result)) {
                setOptions(result.map(country => ({ label: country.name, value: country.name })));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchFilteredData();
        fetchCountries();
    }, [token, selectedType, selected, currentPage]);

    const parseOperationalCountries = (countries) => {
        if (Array.isArray(countries)) {
            return countries;
        }
        try {
            return JSON.parse(countries);
        } catch (e) {
            return [];
        }
    };

    return (
        <BasicLayout>
            {loading && (
                <div className="spinner-overlay">
                    <CircularProgress />
                </div>
            )}
            <div className='inner-banner'>
                <div className='container'>
                    <h2>Organizations</h2>
                </div>
            </div>
            <div className="container org-listing-wrapper mt-2">
                        <div className='row'>
                            <div className='col-md-3'>
                                <div className='project-filters'>
                                    <div className='card'>
                                    <div className='card-body'>
                                        <div className='form-group location-fild col-md-12'>
                                            <label>Operational Countries</label>
                                            <div className="form-group position-relative">
                                                <Autocomplete
                                                    multiple
                                                    options={options}
                                                    freeSolo
                                                    value={selectedLocations}
                                                    onChange={(event, newValue) => {
                                                        setSelectedLocations(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="" />}
                                                />
                                                <button className="btn btn-primary loc-serch" type="button" onClick={fetchFilteredData}>
                                                        <span class="material-symbols-outlined"> search </span>
                                                    </button>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12 mt-2">
                                                <label>Organization Type</label>
                                                <select
                                                    className="form-control mt-2"
                                                    id="availability"
                                                    name="availability"
                                                    value={selectedType}
                                                    onChange={(event) => {
                                                        handleTypeChange(event);
                                                    }}
                                                >
                                                    <option value="">Select Organization Type</option>
                                                    {organizationTypes?.map((type, index) => (
                                                        <option key={index} value={type.value}>{type.label}</option>
                                                    ))}
                                                </select>
                                        </div>
                                        <div className='form-group project-wise-search col-md-12 mt-2'>
                                            <label>Search by name</label>
                                            <div className="form-group position-relative">
                                            <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search by name"
                                                    aria-describedby="button-addon2"
                                                    value={searchTerm}
                                                    onChange={handleSearchTermChange}
                                                />
                                                <button className="btn btn-primary location-fild" type="button" onClick={fetchFilteredData}>
                                                    <span class="material-symbols-outlined"> search </span>
                                                </button>
                                            </div>
                                            
                                        </div>
                                        <div className='form-group mt-2 float-end'>
                                                <button
                                                    className="btn btn-warning mt-2"
                                                    onClick={() => {
                                                        setSelectedLocations([]);
                                                        setSelectedType('');
                                                        setSearchTerm('');
                                                        fetchFilteredData();
                                                    }}
                                                >
                                                    Clear Filters
                                                </button>
                                        </div>


                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-9'>
                                <div className='row'>
                                {data?.length > 0
                                ? data.map((organization, index) => (
                                    <div className="col-md-6 py-3" key={index}>
                                        <div className="card project-list org-list-wrap shadow">
                                            <div className="card-body">
                                                <div className="company-title d-flex align-items-start justify-content-start">
                                                    
                                                        <div className="profile-wrapper d-inline-flex align-items-center justify-content-center" style={{ backgroundColor: organization?.logo ? '#FFFFFF' : '#fff', color: organization?.logo ? '#ffffff' : '#000' }}>
                                                            {organization?.logo ? (
                                                                <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${organization?.logo}`} alt="Logo" />
                                                            ) : (
                                                                <p className="letter-cap">{organization?.company_name?.charAt(0)}</p>
                                                            )}
                                                        </div>
                                                    
                                                    <div className="meta-info">
                                                        <Link to={`/organization/${organization?.slug}`}>
                                                            <h2>{organization?.company_name}</h2>
                                                        </Link>
                                                        <p>{organization?.organization_type}</p>
                                                        {organization?.operational_countries && (
                                                            <>
                                                                <label>Operational Countries</label>
                                                                <p className='text-wrap'>{parseOperationalCountries(organization?.operational_countries).join(', ')}</p>
                                                            </>
                                                                
                                                        )}
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : (
                                    <div className="col-md-12 mt-5">
                                        <div className="card-shadow">
                                            <h2 className='text-center'>No Records Found !!</h2>
                                        </div>
                                    </div>
                                )}
                                </div>
                            
                            </div>
                            
                        </div>
                    </div>

            {totalPages > 1 && (
                <MDPagination size="medium">
                    {currentPage > 1 && (
                        <MDPagination item onClick={() => handlePageChange(currentPage - 1)}>
                            <Icon>keyboard_arrow_left</Icon>
                        </MDPagination>
                    )}
                    {Array.from({ length: totalPages }, (_, i) => (
                        <MDPagination item
                            key={i + 1}
                            active={currentPage === i + 1}
                            onClick={() => handlePageChange(i + 1)}
                        >
                            {i + 1}
                        </MDPagination>
                    ))}
                    {currentPage < totalPages && (
                        <MDPagination item onClick={() => handlePageChange(currentPage + 1)}>
                            <Icon>keyboard_arrow_right</Icon>
                        </MDPagination>
                    )}
                </MDPagination>
            )}
        </BasicLayout>
    )
}

export default Index
