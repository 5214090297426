import React, { useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-reset-cover.jpeg";
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import { Tooltip, IconButton, Grid } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function Cover() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const { token } = useParams();
    const navigate = useNavigate()

    const handlePassoword = async () => {
        // Password validation regex
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

        // Check if password meets the regex pattern
        if (!passwordRegex.test(password)) {
            setError("Password format is invalid");
            return;
        }

        // Check if confirm password matches password
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            const response = await axios.post(`${apiUrl}api/reset-password/${token}`, {
                password: password,
            });
            if (response.status === 200) {
                setError("");
                toast.success(response.data.message, {
                    className: "toast-message",
                });
                setPassword("");
                setConfirmPassword("");

            } else {
                setError(response.data.error || 'Something went wrong');
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setError(error.response.data.error);
            } else {
                setError("Something went wrong. Please try again later.");
            }
        }
    };


    return (
        <BasicLayout image={bgImage}>
      <ToastContainer />
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} sm={9} md={6} lg={4} xl={6}>
                    <Card className="w-50 mx-auto">
                        <MDBox
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="success"
                            mx={2}
                            mt={-3}
                            py={2}
                            mb={1}
                            textAlign="center"
                        >
                            <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                                Reset Your Password
                            </MDTypography>
                        </MDBox>
                        <MDBox pt={4} pb={3} px={3}>
                            <MDBox component="form" role="form">
                                <MDBox mb={4} sx={{ position: 'relative' }}>
                                    <MDInput
                                        type="password"
                                        label="New Password"
                                        fullWidth
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                    {error && (
                                        <MDTypography variant="caption" color="error">
                                            {error}
                                        </MDTypography>
                                    )}
                                    <Tooltip
                                        title={
                                            <React.Fragment>
                                                <div>The password must meet the following criteria:</div>
                                                <ul>
                                                    <li>The password contains at least one lowercase letter (a-z)</li>
                                                    <li>The password contains at least one uppercase letter (A-Z)</li>
                                                    <li>The password contains at least one digit (0-9)</li>
                                                    <li>The password contains at least one special character</li>
                                                    <li>The password is at least 8 characters long</li>
                                                </ul>
                                            </React.Fragment>
                                        }
                                        arrow
                                        placement="right"
                                    >
                                        <IconButton sx={{ position: 'absolute', top: '50%', right: 8, transform: 'translateY(-50%)' }}>
                                            <InfoIcon sx={{ fontSize: 16, color: 'dark' }} />
                                        </IconButton>
                                    </Tooltip>
                                </MDBox>
                                <MDBox mb={4} sx={{ position: 'relative' }}>
                                    <MDInput
                                        type="password"
                                        label="Confirm New Password"
                                        fullWidth
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                    />
                                    {error.confirmPassword && (
                                        <MDTypography variant="caption" color="error">
                                            {error.confirmPassword}
                                        </MDTypography>
                                    )}
                                    <Tooltip
                                        title={
                                            <React.Fragment>
                                                <div>The password must meet the following criteria:</div>
                                                <ul>
                                                    <li>The password contains at least one lowercase letter (a-z)</li>
                                                    <li>The password contains at least one uppercase letter (A-Z)</li>
                                                    <li>The password contains at least one digit (0-9)</li>
                                                    <li>The password contains at least one special character</li>
                                                    <li>The password is at least 8 characters long</li>
                                                </ul>
                                            </React.Fragment>
                                        }
                                        arrow
                                        placement="right"
                                    >
                                        <IconButton sx={{ position: 'absolute', top: '50%', right: 8, transform: 'translateY(-50%)' }}>
                                            <InfoIcon sx={{ fontSize: 16, color: 'dark' }} />
                                        </IconButton>
                                    </Tooltip>
                                </MDBox>

                                <MDBox mt={6} mb={1}>
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        fullWidth
                                        onClick={handlePassoword}
                                    >
                                        send
                                    </MDButton>
                                </MDBox>

                                <MDBox mt={3} textAlign="center">
                                    <MDTypography variant="button" color="text">
                                        Remembered your password?{" "}
                                        <MDTypography
                                            component={Link}
                                            to="/sign-in"
                                            variant="button"
                                            color="info"
                                            fontWeight="medium"
                                            textGradient
                                        >
                                            Sign In
                                        </MDTypography>
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                        </MDBox>
                    </Card>
                    </Grid>
                    </Grid>
                </BasicLayout>
                );
}

                export default Cover;
