import React, { useEffect, useState } from 'react';
import BasicLayout from 'layouts/authentication/components/BasicLayout';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import { useNavigate, useParams } from 'react-router-dom';
import ShowContactModal from './ShowContactModal';

const View = () => {
    const { slug } = useParams();
    const [userDetails, setUserDetails] = useState([]);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const { token, user } = useSelector(selectAuth);
    const navigate = useNavigate();
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const currentDate = new Date();
    // Extract the current year and month
    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits for month

    // Format the end date
    const endDate = `${currentYear}-${currentMonth}`;
    // Format the max date
    const maxDate = `${currentYear}-${currentMonth}`;

    const fetchUsertDetails = async () => {
        console.log(slug)
        try {
            const response = await fetch(`${apiUrl}api/experts/${slug}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (response.ok) {
                setUserDetails(result);
                console.log(result)

            } else {
                setError('Failed to fetch experts details.');
            }
        } catch (error) {
            console.error('Error fetching role details:', error);
            setError('Failed to fetch experts details. Please try again later.');
        }
    };

    const downloadPDF = () => {
        // Retrieve the URL of the PDF file from formData.resume_attachment
        const pdfUrl = `${apiUrl}${node === 'production' ? 'public/' : ''}storage/` + userDetails?.details?.resume_attachment;

        // Create a hidden anchor element
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank'; // Open the link in a new tab
        link.download = 'resume.pdf'; // Set the filename for download

        // Append the anchor element to the body and trigger a click event
        document.body.appendChild(link);
        link.click();

        // Remove the anchor element from the body
        document.body.removeChild(link);
    };
    const toggleFeatures = () => {
        setShowFullDescription(!showFullDescription);
    };

    function convertDateFormat(dateString) {
        const dateParts = dateString.split('-');
        const year = dateParts[0];
        const month = new Date(dateString + '-01').toLocaleString('en-us', { month: 'short' });
        return `${month} ${year}`;
    }

    const handleContact = (email) => {
        if (email) {
            setShowModal(true)

        }
    }

    useEffect(() => {
        fetchUsertDetails();
    }, []);

    return (
        <BasicLayout>
            <ShowContactModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                user={user}
                token={token}
                email={userDetails?.email}
                name={userDetails?.name}

            />
            <div className='inner-banner'>
                <button className='btn btn-dark float-end' onClick={() => navigate(-1)}>Back</button>
            </div>

<div className="container profetional-details-wrapper mt-1">
    {token && user?.user?.role_id == 3 && (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-end p-3">
                            
                                <button className="btn btn-primary d-flex mx-3" onClick={() => handleContact(userDetails?.email)}
                                >
                                    Contact <i className="material-icons px-1">mail</i>
                                </button>
                                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )}

    <div className='card shadow p-5'>
        <div className='row prof-info'>
                <div className="col-lg-3 mb-4 profetional-profile mb-lg-0">
                        {userDetails?.details?.profile_picture ?
                            <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${userDetails?.details?.profile_picture}`} alt="Profile picture" className="img-fluid" />

                            :
                            <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="..." className="img-fluid" />
                        }
                </div>
                <div className="col-lg-9 px-xl-4">
                    <div className="meta-info-1">
                        <h2>{userDetails?.name}</h2>
                        <hr></hr>
                        <h4 className="">{userDetails?.details?.designation}</h4>
                        <ul className="list-unstyled">
                        {/* <li className="mb-3 display-7"><span className="text-secondary me-2 font-weight-bold"><strong>Email:</strong></span> {userDetails?.email}</li>
                        <li className="mb-3 display-7"><span className="text-secondary me-2 font-weight-bold"><strong>Phone:</strong></span>{userDetails?.phone}</li> */}
                        {userDetails?.details?.infracon_id && (
                            <li><span>Infracon Id:</span> {userDetails?.details?.infracon_id}</li>
                        )}
                        {userDetails?.details?.availability && (
                            <li><span><strong>Availability:</strong></span> {userDetails?.details?.availability}</li>
                        )}
                        {userDetails?.details?.years_of_experience && (
                            <li><span>Experience:</span> {userDetails?.details?.years_of_experience} Years</li>
                        )}

                    </ul>
                    </div>
                    
                </div>
        </div>
    </div>
                        
    <div className="row meta-data align-items-center">
                {userDetails?.details?.about ?
                    <div className="col-lg-12 mt-5 px-4">
                        <div>
                            <h2 className="">About {userDetails?.name && userDetails.name.split(' ')[0]}</h2>
                            <hr></hr>
                            <p>{userDetails?.details?.about}</p>
                        </div>
                    </div>
                    :
                    <div className='mt-5'></div>
                }

            <div className="col-lg-12 mt-2 px-4">
                <div className="row">
                    <div className="col-lg-12 mb-4 mb-sm-5">
                        <div className="mb-4">
                            <h2 className="">Sectors</h2>
                            <hr></hr>
                            {userDetails?.sectors}
                            {userDetails?.details?.sectors && userDetails.details.sectors.map((sector, index) => (
                                <div className="badge bg-secondary mx-2 my-2 mb-3 p-3" key={index}>{sector}</div>
                            ))}

                        </div>
                        {userDetails?.details?.experience && (
                            <div className='mt-3'>
                                <h2>Experience</h2>
                                <hr></hr>
                                <div className="accordion" id="accordionExample">
                                    {userDetails?.details?.experience.map((exp, index) => (
                                        <div className="accordion-item mt-2" key={index}>
                                            <h2 className="accordion-header" id={`heading${index}`}>
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                                                    {exp.title} <span className='px-1'> - {exp?.company_name}</span> <span className='px-1'> ({convertDateFormat(exp?.start_date)} - {endDate === exp.end_date ? 'Present' : convertDateFormat(exp?.end_date)})</span>
                                                </button>

                                            </h2>
                                            <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                                <div className="accordion-body d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h5 className='text-bold'>{exp.title}</h5>
                                                        <small className='text-muted fs-8'>{convertDateFormat(exp?.start_date)} - {endDate === exp.end_date ? 'Present' : convertDateFormat(exp?.end_date)}  {exp?.employment_type && (` (${exp?.employment_type})`)}</small>
                                                        <h6 className="pt-3"><strong>Company Name: </strong><span>{exp?.company_name}</span></h6>
                                                        <h6 className="pt-1"><strong>Location: </strong><span>{exp?.location}</span></h6>
                                                        {exp?.description && (
                                                            <p className='text-muted pt-3'>
                                                                <small>{showFullDescription ?
                                                                    <span dangerouslySetInnerHTML={{ __html: exp?.description }} /> :
                                                                    <span dangerouslySetInnerHTML={{ __html: exp?.description.substring(0, 200) + "..." }} />
                                                                }
                                                                    {exp?.description.length > 200 && (
                                                                        <span className="text-secondary" style={{ cursor: 'pointer' }} onClick={toggleFeatures}>
                                                                            {showFullDescription ? " View less" : " View more"}
                                                                        </span>
                                                                    )}
                                                                </small>
                                                            </p>
                                                        )}
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </div>

                            </div>
                        )}
                        <div className='mt-3'>
                            <h2 >Other Info  </h2>
                            <hr></hr>
                            <ul className="list-unstyled mb-4">
                                {userDetails?.details?.current_location && (
                                    <li className="mb-3 display-7"><span className="text-secondary me-2 font-weight-bold"><strong>Current Location:</strong></span> {userDetails?.details?.current_location}</li>
                                )}
                                {userDetails?.details?.preferred_location?.length > 0 && (
                                    <li className="mb-3 display-7">
                                        <span className="text-secondary me-2 font-weight-bold"><strong>Preferred Location:</strong></span>
                                        {userDetails.details.preferred_location.join(' || ')}
                                    </li>
                                )}


                                {userDetails?.details?.current_employer && (
                                    <li className="mb-3 display-7">
                                        <span className="text-secondary me-2 font-weight-bold"><strong>Current Employer:</strong></span>
                                        {userDetails.details.current_employer}
                                    </li>
                                )}

                            </ul>

                        </div>
                    </div>
                </div>
            </div>
    </div>
</div>
                

        </BasicLayout>
    );
}

export default View;
