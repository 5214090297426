import React from 'react';
import BasicLayout from "layouts/authentication/components/BasicLayout";
import MDButton from 'components/MDButton';
import { Grid } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <BasicLayout>
            <Grid container justifyContent="center" alignItems="center">
                <div className="container mt-5 p-5">
                    <h2 className="text-center mb-4 mt-5">Privacy Policy</h2>
                    <div className="policy-content text-start">
                        <h5>1. Introduction</h5>
                        <p>Welcome to Proxtal! As part of Deqtal Consulting India, we are committed to safeguarding your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect data when users access and use our platform.</p>

                        <h5 className='mt-2'>2. Types of Personal Information Collected</h5>
                        <p>We collect various types of personal information to provide effective services. The categories of personal data we collect include:</p>
                        <ul>
                            <li><strong>Profile Information:</strong> Name, Email address, Contact number, Professional background, Skills and qualifications, Affiliation with a company/organization</li>
                            <li><strong>Project-related Data:</strong> Information related to projects (interests, participation), Collaboration history, Preferences and settings</li>
                            <li><strong>Technical Information:</strong> IP address, Browser type and version, Operating system, Usage patterns on our platform</li>
                        </ul>

                        <h5 className='mt-2'>3. How We Collect Personal Information</h5>
                        <p>We collect personal data through the following methods:</p>
                        <ul>
                            <li>When you sign up for alerts or register on Proxtal.</li>
                            <li>When you submit your professional details during registration.</li>
                            <li>Through surveys and forms (if you choose to participate).</li>
                            <li>Automatically through cookies and tracking technologies when you visit our platform.</li>
                        </ul>

                        <h5 className='mt-2'>4. Purpose of Data Processing</h5>
                        <p>We process your personal information for the following purposes:</p>
                        <ul>
                            <li>Connecting Professionals: Facilitating connections between international development professionals and relevant projects.</li>
                            <li>Project Collaboration: Enabling communication and collaboration among professionals and project stakeholders.</li>
                            <li>Job Opportunities: Matching professionals with suitable job openings.</li>
                            <li>Platform Improvement: Analyzing usage patterns to enhance our services.</li>
                        </ul>

                        <h5 className='mt-2'>5. Data Sharing and Recipients</h5>
                        <p>We may share your personal data with the following recipients:</p>
                        <ul>
                            <li>Project Stakeholders: Other professionals, project managers, and organizations involved in specific projects.</li>
                            <li>Service Providers: Third-party providers assisting with platform maintenance, hosting, and security.</li>
                            <li>Affiliated Companies: Within the Deqtal Consulting India brand.</li>
                        </ul>

                        <h5 className='mt-2'>6. Data Retention</h5>
                        <p>We retain personal data for as long as necessary to fulfill the purposes outlined above. Retention periods may vary based on legal requirements and business needs.</p>

                        <h5 className='mt-2'>7. User Rights</h5>
                        <p>As a user of Proxtal, you have the following rights:</p>
                        <ul>
                            <li>Access: Request access to your personal data.</li>
                            <li>Rectification: Correct any inaccurate or incomplete data.</li>
                            <li>Erasure: Request deletion of your data (subject to legal obligations).</li>
                            <li>Restriction: Limit the processing of your data.</li>
                            <li>Portability: Receive your data in a structured format.</li>
                            <li>Objection: Object to specific processing activities.</li>
                        </ul>

                        <h5 className='mt-2'>8. Security Measures</h5>
                        <p>We implement technical and organizational measures to protect personal data from unauthorized access, loss, or misuse.</p>

                        <h5 className='mt-2'>9. Cookies and Tracking Technologies</h5>
                        <p>We use cookies to enhance your experience on Proxtal. You can manage cookie preferences through your browser settings.</p>

                        <h5 className='mt-2'>10. Contact Us</h5>
                        <p>If you have any questions or concerns regarding your personal data, please contact our Data Protection Officer at <a href="mailto:gdpr@proxtal.com">gdpr@proxtal.com</a>.</p>
                    </div>
                </div>
            </Grid>
        </BasicLayout>
    )
}

export default PrivacyPolicy;
