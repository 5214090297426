import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const Password = (props) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';

    const handleSubmit = async (e) => {
        e.preventDefault();
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        // Password strength validation
        if (!passwordRegex.test(newPassword)) {
            setErrorMessage('Password must contain at least 8 characters, including uppercase, lowercase, numbers, and special characters.');
            setSuccessMessage('');
            return;
        }

        // New password and confirm password match validation
        if (newPassword !== confirmPassword) {
            setErrorMessage('New password and confirm password do not match.');
            setSuccessMessage('');
            return;
        }
        try {
            const response = await axios.post(`${apiUrl}api/update-password`, {
                old_password: oldPassword,
                password: newPassword,
                password_confirmation: confirmPassword,
            },
                {
                    headers: {
                        'Authorization': `Bearer ${props.token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            setSuccessMessage(response.data.message);
            toast.success(response.data.message, {
                className: "toast-message",
            });
            setErrorMessage('');
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');

        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setErrorMessage(error.response.data.error);
            } else {
                setErrorMessage('An error occurred. Please try again later.');
            }
            setSuccessMessage('');
        }
    };

    return (
        <div className="card mb-4 mt-3">
            <div className="card-header">
                <h5>Change Password</h5>
            </div>
            <div className="card-body">
            <p className='text-muted font-italic'>Password must contain at least 8 characters, including uppercase, lowercase, numbers, and special characters.</p>

                <form onSubmit={handleSubmit}>
                    <div className='row mt-3'>
                        <div className="form-group col-md-6">
                            <label htmlFor="oldPassword">Current Password <span className='text-danger'>*</span></label>
                            <input type="password" className="form-control mt-2" id="oldPassword" name="oldPassword" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} required />
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="newPassword">New Password <span className='text-danger'>*</span></label>
                            <input type="password" className="form-control mt-2" id="newPassword" name="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                            {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}
                        </div>
                        <div className="form-group col-md-6 mt-3">
                            <label htmlFor="confirmPassword">Confirm Password <span className='text-danger'>*</span></label>
                            <input type="password" className="form-control mt-2" id="confirmPassword" name="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                        </div>
                    </div>
                    <div className="text-center mt-5 mb-4">
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Password;
