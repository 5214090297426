import BasicLayout from 'layouts/authentication/components/BasicLayout';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../store/slices/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { Grid, Autocomplete, TextField, Icon, CircularProgress } from '@mui/material';
import ShowApplyModal from './ShowApplyModal';
import { formatDistanceToNow, format, parseISO } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import countryData from '../../assets/data/country-state.json';
import MDPagination from 'components/MDPagination';

const Index = () => {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const apiUrl = process.env.REACT_APP_API_BASE_URL || '';
    const node = process.env.REACT_APP_NODE_ENV || 'local';
    const [showFullFeatures, setShowFullFeatures] = useState(false);
    const { token, user, isNewUser } = useSelector(selectAuth);
    const [companyName, setSelectedCompanyName] = useState('');
    const [projectId, setSelectedProjectId] = useState('');
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [stateCountryList, setStateCountryList] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [selectedSector, setSelectedSector] = useState('');
    const [selectedStage, setSelectedStage] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [positions, setPositions] = useState([]);

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const toggleFeatures = () => {
        setShowFullFeatures(!showFullFeatures);
    };
    const handleApply = (name, id, jobs) => {
        // const personnelDetails = key_personnel_details || [];
        // const keyPersonnelNames = Array.isArray(key_personnel_details)
        //     ? personnelDetails.map(person => person.name)
        //     : [];
        
        setSelectedCompanyName(name)
        setSelectedProjectId(id)
        setShowModal(true)
        setPositions(jobs)
    }

    const convertDate = (date) => {
        const deadline = date;
        const formattedDeadline = deadline ? format(parseISO(deadline), 'MMMM d, yyyy') : 'N/A';
        return formattedDeadline;
    }

    // Handle selection of a sector
    const handleSectorChange = (event) => {
        const sectorName = event.target.value;
        setSelectedSector(sectorName);
    };

    const handleStageChange = (event) => {
        const value = event.target.value;
        setSelectedStage(value);
    };

    const handleDateChange = (event) => {
        const value = event.target.value;
        setSelectedDate(value);
    };

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value); // Added handler for search term change
    };

    const fetchFilteredProjects = async () => {
        try {
            setLoading(true);
            // Build query parameters for filtering
            const queryParams = new URLSearchParams();
            if (selectedLocations.length > 0) {
                queryParams.append('locations', selectedLocations.join(','));
            }

            if (selectedSector) {
                queryParams.append('sector', selectedSector);
            }

            if (selectedStage) {
                queryParams.append('stage', selectedStage);
            }

            if (selectedDate) {
                queryParams.append('posted_date', selectedDate);
            }

            if (searchTerm) {
                queryParams.append('search', searchTerm); // Added project name filter
            }
            queryParams.append('page', currentPage);
            queryParams.append('items_per_page', itemsPerPage);

            const response = await fetch(`${apiUrl}api/all-projects?${queryParams.toString()}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            if (result) {
                console.log(result)
                setLoading(false);
                setData(result?.data?.data || []);
                setCurrentPage(result.data.current_page); // Set current page
                setTotalPages(result.data.last_page); // Set total pages
            }

        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
    };
    const handlePageChange = (page) => {
        setCurrentPage(page); // Update current page
    };

    const fetchCountryStateList = () => {
        if (countryData && countryData.countries) {
            const list = countryData.countries.flatMap((country) =>
                country.states ? country.states.map((state) => `${state}, ${country.country}`) : []
            );
            setStateCountryList(list);
        }
    }
    const fetchSectorsData = async () => {
        try {
            const response = await fetch(`${apiUrl}api/get-sectors`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const result = await response.json();
            setSectors(result);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchCountryStateList();
        fetchSectorsData();
        fetchFilteredProjects(); // Fetch data initially with filtering
    }, [token, selectedSector, selectedStage, selectedDate, currentPage]);

    return (
        <BasicLayout>
            <ToastContainer />
            {loading && (
                <div className="spinner-overlay">
                    <CircularProgress />
                </div>
            )}
            <ShowApplyModal
                show={showModal}
                type='project'
                handleClose={() => setShowModal(false)}
                user={user}
                token={token}
                companyName={companyName}
                projectId={projectId}
                positions={positions}
                handleDataFetch={fetchFilteredProjects}
            />
            <div className='inner-banner'>
                <div className='container'>
                    <h2>All Projects</h2>
                </div>
            </div>
            <Grid justifyContent="center" alignItems="center" className={showModal ? 'blurred-background' : ''}>
                
                    <div className="container mt-2 p-2">
                        <div className="row justify-content-center">
                            <div className='col-md-3 position-sticky'>
                                <div className='project-filters'>
                                    <div className='card'>
                                    <div className='card-title text-center'>
                                        {/* <h3>Looking for Projects?</h3> */}
                                    </div>
                                    <div className='card-body'>
                                        <div className='row'>
                                        <div className='form-group project-wise-search col-md-12'>
                                                <div className="form-group position-relative mb-3">
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search by project name"
                                                        aria-describedby="button-addon2"
                                                        value={searchTerm}
                                                        onChange={handleSearchTermChange}
                                                    />
                                                    <button className="btn btn-primary" type="button" onClick={fetchFilteredProjects}>
                                                    <span class="material-symbols-outlined"> search </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className='form-group location-fild col-md-12'>
                                                <label>Search by Location</label>
                                                <div className="form-group position-relative">
                                                    <Autocomplete
                                                        multiple // Allows multiple selections
                                                        options={stateCountryList}
                                                        freeSolo
                                                        value={selectedLocations}
                                                        onChange={(event, newValue) => {
                                                            setSelectedLocations(newValue);
                                                        }}
                                                        renderInput={(params) => <TextField {...params} label="" />}
                                                    />
                                                    <button className="btn btn-primary loc-serch mt-1" type="button" onClick={fetchFilteredProjects}>
                                                        <span class="material-symbols-outlined"> search </span></button>
                                                </div>
                                            </div>
                                    <hr className='mt-2'></hr>
                                            <div className="form-group col-md-12">
                                            <label>Search by Sector</label>
                                                <select
                                                    className="form-control"
                                                    id="type"
                                                    name="type"
                                                    value={selectedSector}
                                                    onChange={(event) => {
                                                        handleSectorChange(event);
                                                    }}
                                                >
                                                    <option value="">Select Sector</option>
                                                    {sectors.map((sector) => (
                                                        <option key={sector.name} value={sector.name}>{sector.name}</option>
                                                    ))}
                                                </select>
                                            </div>

                                            <div className="form-group col-md-12">
                                            <label>Search by Stage</label>
                                                <select
                                                    className="form-control"
                                                    id="stage"
                                                    name="stage"
                                                    value={selectedStage}
                                                    onChange={(event) => {
                                                        handleStageChange(event);
                                                    }}
                                                >
                                                    <option value="">Select Stage</option>
                                                    <option key='Current' value='Current'>Current</option>
                                                    <option key='Proposal' value='Proposal'>Proposal</option>
                                                </select>
                                            </div>

                                            <div className="form-group col-md-12">
                                            <label>Search by Date</label>
                                                <select
                                                    className="form-control"
                                                    id="posted_date"
                                                    name="posted_date"
                                                    value={selectedDate}
                                                    onChange={(event) => {
                                                        handleDateChange(event);
                                                        fetchFilteredProjects(); // Trigger fetch on posted date change
                                                    }}
                                                >
                                                    <option value="">Posted Timeframe</option>
                                                    <option key='all' value='all'>All time</option>
                                                    <option key='recent' value='recent'>Recent</option>
                                                    <option key='last_week' value='last_week'>Last 7 days</option>
                                                    <option key='last_month' value='last_month'>Last 30 days</option>
                                                </select>
                                            </div>

                                            

                                            <div className='form-group col-md-12'>
                                                <button
                                                    className="btn btn-warning mt-2"
                                                    onClick={() => {
                                                        setSelectedLocations([]);
                                                        setSelectedSector('');
                                                        setSelectedStage('');
                                                        setSelectedDate('');
                                                        setSearchTerm('');
                                                        fetchFilteredProjects(); // Trigger fetch on clearing filters
                                                    }}
                                                >
                                                    Clear Filters
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-9'>
                                
                            {data?.length > 0
                                ?
                                <>
                                    {data?.map((project, index) => (
                                        <div className="col-md-12 py-3" key={index}>
                                            <div className="card project-list shadow">
                                                <div className="card-body">
                                                    <div className="row align-items-center company-title">
                                                        <div className="col-md-2">
                                                            <div className="profile-wrapper d-inline-flex align-items-center justify-content-center" style={{backgroundColor: project?.company?.logo ? '#fff' : '#fff', color: user?.details?.profile_picture ? '#fff' : '#000' }}>

                                                                {project?.company?.logo ? (
                                                                    <img src={`${apiUrl}${node === 'production' ? 'public/' : ''}storage/${project?.company?.logo}`} alt="Profile picture" className="img-fluid" />
                                                                ) : (
                                                                    <p className='letter-cap'>{project?.company?.company_name?.charAt(0)}</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <Link to={`/project/${project?.slug}`}>
                                                                <h2 className="text-truncate" style={{ cursor: 'pointer' }}>{project?.name}</h2>
                                                            </Link>
                                                            <p>{project?.company?.company_name}</p>
                                                        </div>
                                                        <div className='col-md-3 post-meta'>
                                                            <p>
                                                                <i className="material-icons fs-6" title="Posted on">event</i>
                                                                <span className="fs-6 text-muted"> Posted on  {convertDate(project?.created_at)}</span>
                                                            </p>
                                                            {convertDate(project?.deadline) && (
                                                                <p>
                                                                    <i className="material-icons fs-6" title="Closing on">schedule</i>
                                                                    <span className="fs-6 text-muted"> Closing on  {convertDate(project?.deadline)}</span>
                                                                </p>
                                                            )}
                                                            <p>
                                                                <i className="material-icons fs-6" title={project?.stage === "Current" ? "Job openings currently available" : "This organization is bidding on a project and offering a job if the project is approved"}
                                                                >info</i>
                                                                <span className="fs-6 text-muted"> {project?.stage}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <hr className='my-1'></hr>
                                                    <div className='meta-info'>
                                                        <div className='row mb-3'>
                                                            <div className="col-md-3 pt-2">
                                                                <label>Sector</label>
                                                                <h6>{project?.sector}</h6>
                                                            </div>
                                                            <div className="col-md-3 pt-2 ">
                                                                <label>Sub Sector</label>
                                                                <h6>{project?.sub_sector}</h6>
                                                            </div>
                                                            <div className="col-md-3 pt-2 float-end">
                                                                <label>Location</label>
                                                                <h6>{project?.location}</h6>
                                                            </div>
                                                            <div className="col-md-3 pt-2 float-end">
                                                                <label>Required Positions</label>
                                                                <h6>
                                                                    {project?.jobs?.map(job => job?.title).join(', ')}
                                                                </h6>
                                                            </div>

                                                        </div>
                                                    </div>
                                                   
                                                    {token ? (
                                                        user && user?.user?.role_id === 4 ? (
                                                            project?.applications.some(application => application.user_id === user.user.id) ? (
                                                                <>
                                                                    <div className="col-md-12 d-flex justify-content-center mt-5 mb-3">
                                                                        <span className="text-success ">Application already submitted</span>
                                                                    </div>
                                                                </>

                                                            ) : (

                                                                <div className="col-md-12 d-flex justify-content-center mt-5 mb-3">
                                                                    <button className="btn btn-primary d-flex align-items-center justify-content-center rounded-pill w-30 mx-3" onClick={() => handleApply(project?.company?.company_name, project?.id, project?.jobs)} >
                                                                        Apply <i className="material-icons ml-2">arrow_forward</i>
                                                                    </button>
                                                                </div>
                                                            )
                                                        ) :
                                                            <></>
                                                    ) : (
                                                        <>
                                                        </>
                                                        // <div className="col-md-12 d-flex justify-content-center mt-5 mb-3">
                                                        //     <button className="btn btn-primary d-flex align-items-center justify-content-center rounded-pill w-30 mx-3" onClick={() => navigate('/sign-in')}
                                                        //     >
                                                        //         Login to Apply <i className="material-icons ml-2">arrow_forward</i>
                                                        //     </button>
                                                        // </div>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </>
                                :
                                <>
                                    <div className="col-md-12 mt-5">
                                        <div className="card-shadow">
                                            <h2 className='text-center'>No Records Found !!</h2>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>

                        </div>

                    </div>
                
            </Grid>
            {/* Pagination */}
            {totalPages > 1 && (
                <MDPagination size="medium">
                    {currentPage > 1 && (
                        <MDPagination item onClick={() => handlePageChange(currentPage - 1)}>
                            <Icon>keyboard_arrow_left</Icon>
                        </MDPagination>
                    )}
                    {Array.from({ length: totalPages }, (_, i) => (
                        <MDPagination item
                            key={i + 1}
                            active={currentPage === i + 1}
                            onClick={() => handlePageChange(i + 1)}
                        >
                            {i + 1}
                        </MDPagination>
                    ))}
                    {currentPage < totalPages && (
                        <MDPagination item onClick={() => handlePageChange(currentPage + 1)}>
                            <Icon>keyboard_arrow_right</Icon>
                        </MDPagination>
                    )}
                </MDPagination>
            )}
        </BasicLayout>

    );
};

export default Index;
